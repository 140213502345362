import React, { useEffect, useMemo } from 'react';
import ActiveFilters from '../../../shared/components/activeFilters';
import CustomTable, { ActionMenu, CustomPagination, HeaderItem, TagRender } from '../../../shared/components/table';
import { Autocomplete, MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import useFetch from '../../../shared/components/userFetch';
import SearchIcon from "../../../../assets/fonts/Search.svg";
import usePatch from '../../../shared/components/usePatch';
import { showToast } from '../../../shared/components/showNotification';
import InputSearchFilter from '../../../shared/components/InputSearchFilter';
import { connect } from 'react-redux';
import { setPageCount } from '../../../../reducers/common';
import Loader from '../../../shared/components/loader';

const ArchiveUsersList = ({
    handleOnFilterChange,
    filterData,
    filterState,
    setFilterState,
    handleSort,
    pageCount,
    setPageCount
}) => {
    const { data: responseData, isLoading, error, refetch, isFetching } = useFetch('/api/v2/entity_management/users/archived', { filters: filterData, pagination: { page: filterState.page, limit: filterState.limit }, sort: filterState.sort });
    const { data: baseData, isLoading: baseDataLoading } = useFetch('/api/v2/entity_management/users/base_data?activated_eq=false');
    const { mutate, isLoading: isRestoring } = usePatch();
    const { data = [], metadata = {} } = responseData || {};
    const { first_names = [], last_names = [], employee_ids = [], usernames = [], groups = [], permissions: {allow_restore = true} = {} } = baseData || {};
    const callRefetch = () => {
        refetch({ filters: filterData, pagination: { page: filterState.page, limit: filterState.limit }, sort: filterState.sort });
    }

    useEffect(() => {
        metadata.count && setPageCount({ulist: {
          user_list: pageCount.ulist.user_list,
          arch: metadata.count || pageCount.ulist.arch 
        }})
      }, [metadata.count]);

    useEffect(() => {
        if (!isLoading) {
            callRefetch();
        }
    }, [filterState]);

    const groupsFilterData = [{ label: 'All', value: 'All' }, ...groups.map(e => ({ ...e, label: e.key, value: e.value }))];

    const handleRestore = (ele, setClose) => {
        mutate({ id: ele.id }, { 
            onSuccess: () => {showToast({ message: 'Restored Successfully' }); callRefetch()},
            onError: (e) => {
                showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
              }
         }, `/api/v2/entity_management/users/${ele.id}/restore`);
        if (setClose) {
            setClose();
        }
    }

    const headers = [
        <TableCell className='left'>
            <HeaderItem
                title="First Name"
                isSort
                handleSort={() => handleSort('first_name')}
                filter={<Autocomplete
                    size="small"
                    defaultValue="All"
                    value={filterData['first_name_cont'] || 'All'}
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.value === value}
                    onChange={(_, v) => handleOnFilterChange(v.value || 'All', 'first_name_cont')}
                    options={[{ label: 'All', value: 'All' }, ...first_names.map(e => ({ ...e, label: e.key || '', id: e.value }))]}
                    popupIcon={<img src={SearchIcon} />}
                    sx={{ width: "100%", background: "#fff", border: "none" }}
                    renderInput={(params) => <TextField {...params} label="Search" />} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Last Name"
                isSort
                handleSort={() => handleSort('last_name')}
                filter={<Autocomplete
                    size="small"
                    defaultValue="All"
                    value={filterData['last_name_cont'] || 'All'}
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.value === value}
                    onChange={(_, v) => handleOnFilterChange(v.value || 'All', 'last_name_cont')}
                    options={[{ label: 'All', value: 'All' }, ...last_names.map(e => ({ ...e, label: e.key || '', id: e.value }))]}
                    popupIcon={<img src={SearchIcon} />}
                    sx={{ width: "100%", background: "#fff", border: "none" }}
                    renderInput={(params) => <TextField {...params} label="Search" />} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Employee Id"
                isSort
                handleSort={() => handleSort('employee_id')}
                filter={<Autocomplete
                    size="small"
                    defaultValue="All"
                    disableClearable
                    value={filterData['employee_id_cont'] || 'All'}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    onChange={(_, v) => handleOnFilterChange(v.value || 'All', 'employee_id_cont')}
                    options={[{ label: 'All', value: 'All' }, ...employee_ids.map(e => ({ ...e, label: e.key || '', id: e.value }))]}
                    popupIcon={<img src={SearchIcon} />}
                    sx={{ width: "100%", background: "#fff", border: "none" }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Search" />} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="User Name"
                isSort
                handleSort={() => handleSort('username')}
                filter={<Autocomplete
                    size="small"
                    defaultValue="All"
                    disableClearable
                    value={filterData['username_cont'] || 'All'}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    onChange={(_, v) => handleOnFilterChange(v.value || 'All', 'username_cont')}
                    options={[{ label: 'All', value: 'All' }, ...usernames.map(e => ({ ...e, label: e.key || '', id: e.value }))]}
                    popupIcon={<img src={SearchIcon} />}
                    sx={{ width: "100%", background: "#fff", border: "none" }}
                    renderInput={(params) => <TextField {...params} label="Search" />} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Job Profile"
                // isSort
                // handleSort={() => handleSort("job_profiles_name")}
                filter={
                  <InputSearchFilter name="job_profiles_name_cont" filterData={filterData} handleOnFilterChange={handleOnFilterChange} filterState={filterState} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Groups / Project Sites"
                isSort
                handleSort={() => handleSort('group_name')}
                filter={<Autocomplete
                    size="small"
                    defaultValue="All"
                    value={groupsFilterData.find(e => e.value === filterData['group_id_eq']) || 'All'}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    disableClearable
                    onChange={(_, v) => handleOnFilterChange(v || 'All', 'group_id_eq')}
                    options={groupsFilterData}
                    popupIcon={<img src={SearchIcon} />}
                    sx={{ width: "100%", background: "#fff", border: "none" }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Search" />} />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Last Login"
                isSort
                handleSort={() => handleSort('last_login')}
            />
        </TableCell>,
        <TableCell className="right" style={{ minWidth: '100px' }}>
            <HeaderItem
                title="Actions"
            />
        </TableCell>,
    ];

    const rows = useMemo(() => data.map((ele, index) => {
        const {
            first_name,
            last_name,
            email,
            employee_id,
            username,
            job_profile_names = [],
            group_names = [],
            last_sign_in_at
        } = ele || {};
        return <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell className='left'>
                <div className='flex'>
                    {/* <Checkbox /> */}
                    <div className='flex flex-c'>
                        <div>{first_name}</div>
                        <div className='desc elips150' title={email}>{email}</div>
                    </div>
                </div>
            </TableCell>
            <TableCell>
                {last_name}
            </TableCell>
            <TableCell>
                {employee_id}
            </TableCell>
            <TableCell>
                {username}
            </TableCell>
            <TableCell>
                <TagRender data={job_profile_names} />
            </TableCell>
            <TableCell>
                <TagRender data={group_names} />
            </TableCell>
            <TableCell>
                {last_sign_in_at}
            </TableCell>
            <TableCell className='right' style={{ minWidth: '100px' }}>
                <ActionMenu>
                    {(setClose) => {
                       const menuItems = []
                        if (allow_restore) {
                            menuItems.push(<MenuItem onClick={() => handleRestore(ele, setClose)}>Restore</MenuItem>);
                        }
                        return menuItems;
                    }}
                </ActionMenu>
            </TableCell>
        </TableRow>
    }), [data]);

    if(isLoading){
        return <Loader />
      }

    return (
        <>

            <CustomTable headers={headers} rows={rows} renderActiveFilter={<ActiveFilters filterData={filterState} keyName='ulist' update={setFilterState} />} />
            {!!metadata.count && <CustomPagination
                totalPage={metadata.last}
                lastPage={metadata.last}
                currentPage={filterState.page}
                handlePageChange={
                    (_, nextPage) => {
                        setFilterState((prev) => ({ ...prev, page: nextPage }))
                    }
                }
                rowOptions={[20, 50, 100]}
                rowPerPage={filterState.limit}
                handleRowPerPageChange={
                    (targetValue) => {
                        setFilterState((prev) => ({
                            ...prev,
                            limit: parseInt(targetValue, 10),
                            page: 1,
                        }))
                    }
                }
            />}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pageCount: state.CommonReducer.pageCount
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    setPageCount: (value) => dispatch(setPageCount(value))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ArchiveUsersList);