import React, { useState, useRef, useEffect } from "react";
import ArrowRight from "../../assets/fonts/ArrowRight.svg";
import _ from "lodash";
import Cookies from "js-cookie";
import axios from "../../config/axiosConfig";
import Application from "../../config/ApplicationWrapper";

const QuickLinks = () => {
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [list, setList] = useState([{
        id: 1,
        title: "My Groups",
        desc: "Manage your own groups ",
        total: 0,
        text: "Total Count",
        link: "/users-and-groups?type=mine",
        status: true,
        active: 5,
        archive: 5
    }, {
        id: 2,
        title: "My Associated Groups",
        desc: "Manage your associated groups ",
        total: 0,
        text: "Total Associated Groups",
        link: "/users-and-groups?type=mine",
        status: true,
        active: 5,
        archive: 5

    }, {
        id: 3,
        title: "My Actions",
        desc: "Manage your own actions",
        total: 0,
        text: "Total Action",
        link: "/actions?type=mine",
        status: false,
        variable: "actions_count",
        active: 0,
        archive: 0

    }, {
        id: 4,
        title: "My Trainings",
        desc: "Manage your own trainings ",
        total: 0,
        text: "Total Trainings",
        link: "/rot_dashboard?type=mine",
        status: false,
        variable: "trainings_count",
        active: 0,
        archive: 0

    }]);

    useEffect(() => {
        getPageDetails()
    }, [])

    const getPageDetails = async () => {
        try {
            let resultObj = await axios.get(`${Application.api_url}/api/v2/common/modules_summary`);
            if (resultObj.data) {
                let quickLink = Cookies.get("quick-link");
                let sorted = [];
                if (quickLink) {
                    quickLink = JSON.parse(quickLink);
                    quickLink.map(k => {
                        list.filter(obj => {
                            if (obj.id === k) {
                                if (obj.id == 1) {
                                    obj.active = resultObj.data.active_groups_count;
                                    obj.archive = resultObj.data.archived_groups_count;
                                    obj.total = Number(obj.active) + Number(obj.archive)
                                } else if (obj.id == 2) {
                                    obj.active = resultObj.data.active_associated_groups_count;
                                    obj.archive = resultObj.data.archived_associated_groups_count;
                                    obj.total = Number(obj.active) + Number(obj.archive)
                                } else {
                                    obj.total = resultObj.data[obj.variable]
                                }

                                sorted.push(obj);
                            }
                        })

                    })
                } else {
                    list.filter(obj => {
                        if (obj.id == 1) {
                            obj.active = resultObj.data.active_groups_count;
                            obj.archive = resultObj.data.archived_groups_count;
                            obj.total = Number(obj.active) + Number(obj.archive)
                        } else if (obj.id == 2) {
                            obj.active = resultObj.data.active_associated_groups_count;
                            obj.archive = resultObj.data.archived_associated_groups_count;
                            obj.total = Number(obj.active) + Number(obj.archive)
                        } else {
                            obj.total = resultObj.data[obj.variable]
                        }

                        sorted.push(obj);
                    })
                }
                setList(sorted);
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };

    const drop = (e) => {
        const copyListItems = [...list];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setList(copyListItems);
        let ids = _.map(copyListItems, "id");
        Cookies.set("quick-link", ids)
    };

    return (
        <div className="quick-dlts">
            <h3 className="dashboard-quick-link">Quick Links</h3>
            <div className="quick-links">
                {
                    list &&
                    list.map((item, index) => (
                        <div className="quick-link-cards"
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                            key={index}
                            draggable>
                            <div className="title">
                                <h2>{item.title}</h2>
                                <img src={ArrowRight} alt="" className="cursor-pointer" onClick={(e) => { window.location.href = item.link }} />
                            </div>
                            <p>{item.desc}</p>
                            <div className="d-flex">
                                <div className="details">
                                    <p className="count">{item.total}</p>
                                    <p className="total-count">{item.text}</p>
                                </div>
                                {/* {item.status &&
                                    <div>
                                        <div className="progress" style={{ background: `linear-gradient(to right, #407797 ${(Number(item.active) / (Number(item.active) + Number(item.archive))) * 100}%, #D3D3D3 ${(Number(item.archive) / (Number(item.active) + Number(item.archive))) * 100}%)` }}></div>
                                        <div className="d-flex" style={{justifyContent:"space-between"}}>
                                            <p className="green-dot">● <span className="status">Active : {item.active}</span></p>
                                            <p className="red-dot">● <span className="status">Archive  : {item.archive}</span></p>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default QuickLinks;