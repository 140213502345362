import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Chip, Box, FormControl, Typography } from '@mui/material';

const TagInput = ({ control, setValue, watch, fieldName, stateName, placeholder, label }) => {
    const emails = watch(stateName);
    
    const handleAddEmail = (event) => {
        const email = event.target.value;
        if (event.key === 'Enter' && validateEmail(email)) {
            event.preventDefault();
            setValue(stateName, [...emails, email]);
            setValue(fieldName || 'tag_input', '');
            event.target.focus();
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setValue(stateName, emails.filter((email) => email !== emailToDelete));
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    return (
        <FormControl fullWidth>
            {label && <Box mb={1} mt={1}>
                <Typography variant="body1" className='f-label'>
                    {label}
                </Typography>
            </Box>}
            <Box display="flex" style={{border: '1px solid #D3D3D3', borderRadius: 5, padding: 5}}>
                {emails.map((email) => (
                    <Chip
                        key={email}
                        label={email}
                        onDelete={() => handleDeleteEmail(email)}
                    />
                ))}
                <Controller
                    name={fieldName || "tag_input"}
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            placeholder={placeholder || "Add emails"}
                            onKeyDown={handleAddEmail}
                            variant="outlined"
                            fullWidth
                            className='tag_input'
                            size="small"
                            InputProps={{
                                style: { borderColor: 'transparent' },
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                        />
                    )}
                />
            </Box>
        </FormControl>
    );
};

export default TagInput;