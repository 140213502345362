import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Grid,
} from '@mui/material';
import useFetch from '../../../shared/components/userFetch';
import Loader from '../../../shared/components/loader';

const ViewQR = ({ open, onClose, recordId }) => {
    const { data: qrData, isLoading } = useFetch(`/api/v2/entity_management/groups/${recordId}?view_type=qr_code_info`);
    const { data: { qr_code_details: { download_url, file_name } = {}, company_name = '' } = {} } = qrData || {};
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head><title>Print QR Code</title></head>
            <body>
              <h2>QR Code for ${company_name}</h2>
              <img src="${download_url}" alt="QR Code" style="width: 300px; height: 300px; object-fit: contain;" />
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = download_url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    width: '500px',
                    height: '90%',
                    overflowY: 'auto',
                    margin: 'auto',
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{ display: 'flex', gap: '10px' }}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_9942_109569)">
                            <path d="M12.0002 5.3335H6.66683C5.93045 5.3335 5.3335 5.93045 5.3335 6.66683V12.0002C5.3335 12.7365 5.93045 13.3335 6.66683 13.3335H12.0002C12.7365 13.3335 13.3335 12.7365 13.3335 12.0002V6.66683C13.3335 5.93045 12.7365 5.3335 12.0002 5.3335Z" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.3335 22.6665V22.6798" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.3332 5.3335H19.9998C19.2635 5.3335 18.6665 5.93045 18.6665 6.66683V12.0002C18.6665 12.7365 19.2635 13.3335 19.9998 13.3335H25.3332C26.0696 13.3335 26.6665 12.7365 26.6665 12.0002V6.66683C26.6665 5.93045 26.0696 5.3335 25.3332 5.3335Z" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.3335 9.3335V9.34683" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0002 18.6665H6.66683C5.93045 18.6665 5.3335 19.2635 5.3335 19.9998V25.3332C5.3335 26.0696 5.93045 26.6665 6.66683 26.6665H12.0002C12.7365 26.6665 13.3335 26.0696 13.3335 25.3332V19.9998C13.3335 19.2635 12.7365 18.6665 12.0002 18.6665Z" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22.6665 9.3335V9.34683" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.6665 18.6665H22.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.6665 18.6665V18.6798" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.6665 18.6665V22.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.6665 26.6665H22.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22.6665 22.6665H26.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M26.6665 22.6665V26.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_9942_109569">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    View QR Code
                </Typography>
                {isLoading ? <Loader /> : (
                    <>
                        <Box
                            sx={{
                                bgcolor: '#F6F6F6',
                                padding: 2,
                                mb: 2,
                                borderRadius: '5px'
                            }}
                        >
                            <Typography variant="body1">Company:</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {company_name}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                border: '1px solid #B3C9D5',
                                padding: 2,
                                mb: 2,
                                borderRadius: '5px'
                            }}
                        >
                            <Typography variant="body2" style={{ color: '#407797', fontSize: '12px' }}>
                                Information: Scanning this QR code will add you to the respective group. Please note, if you are not a member of this company, scanning this QR code will not grant you access to the group.
                            </Typography>
                        </Box>
                        <Box style={{ justifyContent: 'center', display: 'flex' }}>
                            <img
                                src={download_url} // URL of the QR code image
                                alt="QR Code"
                                style={{
                                    width: '300px',
                                    height: '300px',
                                    objectFit: 'contain',
                                    marginBottom: '16px',
                                }}
                            />
                        </Box>

                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Button style={{ backgroundColor: '#D9E4EA', color: '#407797', textTransform: 'none' }} variant="contained" onClick={handlePrint}>
                                    Print
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button style={{ backgroundColor: '#407797', color: '#fff', textTransform: 'none' }} variant="contained" onClick={handleDownload}>
                                    Download
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default ViewQR;
