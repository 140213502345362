import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { User } from '../../../HomePage/Login';
import { uploadFiles } from '../../../../utils/bulkUploadLogic';

export default function FileUploadComponent(props) {
  const { show, closepopup, details, parentId, onUploadSuccess } = props;
  let currentUploadCount = 0;

  useEffect(() => {
    toast.options = {
      "preventDuplicates": true
    }
  })

  const handleDragOver = (e) => {
    e.preventDefault();
    var dropArea = document.getElementById('dropArea');
    dropArea.classList.add('dragover');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const items = e.dataTransfer.items;

    $('.drag_folder_upload').show();
    $('.dropArea').hide();
    toast.info('Upload in progress!')
    uploadFiles(items, parentId, closepopup);
    $('.drag_folder_upload--close').show();
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        closepopup();
      }}
      className="qr-modal-wrap add-task-modal pf-model docs-model"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="pf-model-header">
            <p className="pf-model-title">Bulk Upload</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div class="dropArea" id="dropArea" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div class="drag_and_drop">
              <i class="fas fa-download fa-3x"></i>
              <span>DRAG AND DROP HERE</span>
            </div>
          </div >
          <div className="span drag_folder_upload" style={{display:"none", fontWeight:"bold", fontSize:"17px", color:"green"}}>
            Upload in progress, please wait!
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
}
