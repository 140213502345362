import React, { useState } from 'react';
import {
    Button,
    Modal,
    Typography,
    Box,
    Step,
    StepLabel,
    Stepper,
    CircularProgress,
} from '@mui/material';
import {NewUserForm, UserAssignmentForm} from './newUserForm';
import { useForm } from 'react-hook-form';
import useFetch from '../../../shared/components/userFetch';
import FormWrapper from '../../../shared/components/formWrapper';
import { CustomConnector } from '../../../shared/components/table';
import { showToast } from '../../../shared/components/showNotification';
import SubTabs from '../../../shared/components/subTabs';

const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: '12px',
    p: 4
};

const steps = [{label: 'User Information', value: 0}, {label: 'User Assignments', value: 1}];

const NewUser = ({ open, setOpen, initialValues = null, mutate, setFilterState, refetch, isMutating }) => {
    const isEdit = !!initialValues;
    const { data: baseData, isLoading: baseDataLoading } = useFetch('/api/v2/entity_management/users/base_data?create_user=true');
    const {
        groups = [], 
        job_profiles = [],   
        roles = []
    } = baseData || {};
    const defaultValues = isEdit ? {...initialValues} : {
        role_id: '1',
        groups: [],
        job_profiles: [],
        audit_templates: []
    };
    const [activeStep, setActiveStep] = useState(0);
    const { control, register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
        defaultValues
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleNext = async (isActiveStep) => {
        const isValid = await trigger(); // Validate specific fields
        if (isValid) {
            if (typeof isActiveStep === 'number') {
                setActiveStep(isActiveStep)
            } else {
                setActiveStep((prev) => prev + 1);
            }
        }
        return isValid;
        // if (activeStep === 0) {
        //     // Validate EmployeeForm
        //     const isValid = await trigger(); // Validate specific fields
        //     if (isValid) {
        //       setActiveStep((prev) => prev + 1);
        //     }
        //   } else {
        //     setActiveStep((prev) => prev + 1);
        //   }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prev) => prev - 1);
        }
    };

    const onSubmit = (data) => {
        const payload = {
            user: {
                ...data
            }
        }
        if (data.inventory_enabled) {
            payload.user.inventory_enabled = 1;
        }

        if (data.time_tracker_enabled) {
            payload.user.time_tracker_enabled = 1;
        }

        if (isEdit) {
            payload.id = initialValues.id;
        }

        if (data.groups.length) {
            payload.user.groups = data.groups.map(({value}) => value);
        }
        if (data.job_profiles.length) {
            payload.user.profiles = data.job_profiles.map(({value}) => value);
        }
        if (data.audit_templates.length) {
            payload.user.group_templates = data.audit_templates.map(({value}) => value);
        }
        mutate(payload, {
            onSuccess: () => {
                showToast({ message: "User Created Successfully" });
                setOpen(false);
                if (refetch) {
                    refetch();
                }
                if (!isEdit) {
                    setFilterState({page: 1, limit: 20})
                }
            },
            onError: (e) => {
                showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
              }
        });
        // handleClose(); // Close the modal after submission
    };

    const extraProps = {};
    if (isEdit) {
        extraProps.userId = initialValues.id;
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <FormWrapper>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" className="popup-title" component="h2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <g clip-path="url(#clip0_9099_36355)">
                                <path d="M23.8738 15.9999L8.12616 15.9999M16 8.1261L16 23.8738L16 8.1261Z" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_9099_36355">
                                <rect width="32" height="32" fill="white"/>
                                </clipPath>
                            </defs>
                            </svg> {isEdit ? 'Edit User' : 'Add New User'}
                        </Typography>
                        {isEdit ? <SubTabs tabList={steps} activeCallback={(active) => handleNext(active)} /> :
                        <Stepper className='user-group-stepper' activeStep={activeStep} alternativeLabel connector={<CustomConnector />}>
                            {steps.map(({label}) => (
                                <Step style={{ cursor: 'pointer'}} key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>}
                        <form onSubmit={handleSubmit(onSubmit)} style={{height: 'calc(100% - 110px)'}}>
                            <Box sx={{ mt: 2 }} style={{ overflowY: 'auto', height: 'calc(100% - 40px)' }}>
                                {activeStep === 0 && <NewUserForm isEdit={isEdit} roles={roles} control={control} errors={errors} register={register} />}
                                {activeStep === 1 && <UserAssignmentForm {...extraProps} groups={groups} jobProfiles={job_profiles} control={control} setValue={setValue} />}
                            </Box>
                            <Box mt={2} sx={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>
                                <div>
                                    <Button style={{textTransform: 'none', backgroundColor: '#D9E4EA', color: '#407797', outline: 'none'}} disabled={activeStep === 0} onClick={handleBack}>
                                        Previous
                                    </Button>
                                </div>
                                <div>
                                    <Button style={{textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none'}} onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                    {(isEdit || (activeStep === steps.length - 1)) && (
                                        <Button endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} style={{textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none'}} disabled={isMutating} type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    )}
                                    {!isEdit && activeStep === 0 && (
                                        <Button style={{textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none'}} variant="contained" htmlType="button" type="button" color="primary" onClick={() => handleNext()}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </form>
                    </Box>
                </FormWrapper>
            </Modal>
        </>
    );
};

export default NewUser;
