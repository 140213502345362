import { FormControl, Select, MenuItem, Pagination } from "@mui/material";
import React, { useEffect, useRef, useState, useReducer } from "react";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";
import OrderActionMenu from '../components/OrderActionMenu';
import Sorting from "../../../../assets/images/Filter.png";
import SearchIcon from "../../../../assets/images/searchbar.svg";
import { withRouter } from "react-router-dom";
import { titleize } from "../utils";

const initialState = {
  order_request_id_eq: "",
  order_request_group_name_cont: "",
  order_request_created_by_full_name_cont: "",
  inventory_item_supplier_name_cont: "",
  inventory_item_name_cont: "",
  picker_full_name_cont: "",
  status_eq: "",
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "HANDLE INPUT REMOVE":
      return { ...state, [action.field]: "" };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const viewOrderDetails = (itemId) => {
  window.location.href = `/orders/${itemId}`;
};

const ActiveOrders = ({ history }) => {
  const mounted = useRef(false);
  const [search, dispatch] = useReducer(searchReducer, initialState);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedBy, setSortedBy] = useState("updated_at");
  const [sort, setSort] = useState("desc");
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleDropdown = (itemId) => {
    setOpenDropdownId((prevId) => (prevId === itemId ? null : itemId));
  };

  useEffect(() => {
    getPageDetails();
  }, [page, limit]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      mounted.current && getPageDetails();
      mounted.current = true;
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search, sortedBy, sort]);

  const getPageDetails = async () => {
    try {
      await axios
        .get(`${Application.api_url}/api/v2/order_requests`, {
          params: {
            page: page,
            limit: limit,
            "sorts[]": sortedBy + " " + sort,
            ...search,
          },
        })
        .then((res) => {
          let details = res.data;
          setPageDetails(details ? details.data : []);
          setPageCount(
            details && details.metadata ? details.metadata.pages : 0
          );
          setLoading(false);
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const closeDetails = () => {
    setShowOrderView(false)
    setOrderToView(null)
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleTextChanges = (e) => {
    dispatch({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleTextRemove = (name) => {
    dispatch({
      type: "HANDLE INPUT REMOVE",
      field: name,
      payload: "",
    });
  };

  const handleSort = (name) => {
    if (sortedBy == name) {
      setSort(sort == "asc" ? "desc" : "asc");
    } else {
      setSortedBy(name);
      setSort("asc");
    }
  };

  const goTo = async (path) => {
    history.push(path);
  };

  return (
    <div className="order inventory-type container-new">
      <div className="fields-setting tab-lists">
        <div className="asset-active-filters">
          Active Filters :
          <span className="active-filters">
            <span>Order ID :</span>
            <span className="selected">
              {search.order_request_id_eq ? search.order_request_id_eq : "All"}
            </span>
            <span
              className={
                search.order_request_id_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("order_request_id_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Inventory Name :</span>
            <span className="selected">
              {search.inventory_item_name_cont
                ? search.inventory_item_name_cont
                : "All"}
            </span>
            <span
              className={
                search.inventory_item_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("inventory_item_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Requested By :</span>
            <span className="selected">
              {search.order_request_created_by_full_name_cont
                ? search.order_request_created_by_full_name_cont
                : "All"}
            </span>
            <span
              className={
                search.order_request_created_by_full_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("order_request_created_by_full_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Supplier Name :</span>
            <span className="selected">
              {search.inventory_item_supplier_name_cont
                ? search.inventory_item_supplier_name_cont
                : "All"}
            </span>
            <span
              className={
                search.inventory_item_supplier_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("inventory_item_supplier_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Picker Name :</span>
            <span className="selected">
              {search.picker_full_name_cont
                ? search.picker_full_name_cont
                : "All"}
            </span>
            <span
              className={
                search.picker_full_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("picker_full_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Status :</span>
            <span className="selected">
              {search.status_eq ? titleize(search.status_eq) : "All"}
            </span>
            <span
              className={
                search.status_eq ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("status_eq");
              }}
            >
              X
            </span>
          </span>
          <span
            className="active-filters-clear"
            onClick={(e) => {
              dispatch({
                type: "RESET",
              });
            }}
          >
            Clear All
          </span>
        </div>
      </div>
      <div className="page-content mt-3">
        <table>
          <thead>
          <tr>
            <th className="divider">
                <span className="col-name sorting-col">
                  Order ID
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "order_request_id" && sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("order_request_id");
                    }}
                  />
                </span>
              {pageDetails.length > 0 && (
                <div className="search-col order-req-id">
                  <input
                    type="text"
                    name="order_request_id_eq"
                    id="order_request_id_eq"
                    className="search-btn"
                    value={search.order_request_id_eq}
                    placeholder="Search"
                    onChange={(e) => {
                      handleTextChanges(e);
                    }}
                  />
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="search-icon"
                  />
                </div>
              )}
            </th>
            <th className="divider">
                  <span className="col-name sorting-col">
                    Group
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "order_request_group_name" &&
                        sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("order_request_group_name");
                      }}
                    />
                  </span>
              {pageDetails.length > 0 && (
                <div className="search-col">
                  <input
                    type="text"
                    name="order_request_group_name_cont"
                    id="order_request_group_name_cont"
                    className="search-btn"
                    value={search.order_request_group_name_cont}
                    placeholder="Search"
                    onChange={(e) => {
                      handleTextChanges(e);
                    }}
                  />
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="search-icon"
                  />
                </div>
              )}
            </th>
            <th className="divider">
                <span className="col-name sorting-col">
                  Requested By
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "order_request_created_by_full_name" &&
                      sort == "asc"
                        ? "order-desc"
                        : ""
                    }`}
                    onClick={() => {
                      handleSort("order_request_created_by_full_name");
                    }}
                  />
                </span>
              {pageDetails.length > 0 && (
                <div className="search-col">
                  <input
                    type="text"
                    name="order_request_created_by_full_name_cont"
                    id="order_request_created_by_full_name_cont"
                    className="search-btn"
                    value={search.order_request_created_by_full_name_cont}
                    placeholder="Search"
                    onChange={(e) => {
                      handleTextChanges(e);
                    }}
                  />
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="search-icon"
                  />
                </div>
              )}
            </th>
            <th>
              <span className="col-name">Status</span>
              {pageDetails.length > 0 && (
                <div className="search-col">
                  <select
                    type="date"
                    name="status_eq"
                    id="status_eq"
                    className="search-btn"
                    placeholder="All"
                    onChange={(e) => {
                      handleTextChanges(e);
                    }}
                  >
                    <option>All</option>
                    <option value="pending">Pending</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="declined">Declined</option>
                    <option value="request_for_delivery">
                      Request for delivery
                    </option>
                    <option value="delivered">Delivered</option>
                  </select>
                </div>
              )}
            </th>
            <th>
              <span className="col-name">Actions</span>
            </th>
          </tr>
          </thead>
          <tbody>
          {!loading &&
            pageDetails.length > 0 &&
            pageDetails.map((val, index) => {
              return (
                <tr onClick={() => viewOrderDetails(val.identifier)} className="cursor-override">
                  <td>
                    <div className="star-unstar">
                        <span>
                          #{val.id}
                        </span>
                      {val.is_urgent && (
                        <span className="urgent">Urgent</span>
                      )}
                    </div>
                  </td>
                  <td>{val.group && val.group.name}</td>
                  <td>
                    <p className="action-by">
                      {val.created_by &&
                        val.created_by.full_name}
                    </p>
                    <p className="action-time">
                      {val.created_at}
                    </p>
                  </td>
                  <td>
                      <span className={`${val.status.toString().toLowerCase()}-order`}>
                        {val.status_text.toString()}
                      </span>
                  </td>
                  <td>
                    <OrderActionMenu
                      itemId={val.identifier}
                      isOpen={openDropdownId === val.identifier}
                      onToggle={toggleDropdown}
                      history={history}
                    />
                  </td>
                </tr>
              );
            })}
          <Loader pageDetails={pageDetails} loading={loading} colSpan={14} />
          </tbody>
        </table>
      </div>
      <div className="page-limits">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLoading(true);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pageCount || 1}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default withRouter(ActiveOrders);
