import { Box, Breadcrumbs, Checkbox, FormControlLabel, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { DynamicSVG } from "./table";

const HeaderWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back-arrow {
    display: flex;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    width: 42px;
    height: 42px;
    padding: 2px;
    alignitems: center;
    background: #fff;
    margin-right: 12px;
    > a {
      lineheight: normal;
    }
  }
  .flex {
    display: flex;
  }
`;

const ViewPageHeader = ({ backUrl, title, items = [], extras = [] }) => {
  return (
    <HeaderWrapper>
      <Box className="flex">
        <div className="back-arrow">
          <Link to={backUrl}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M20.6666 6.66663L11.3333 16L20.6666 25.3333"
                stroke="#080808"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>
        <Box className="flex" flexDirection="column">
          <Typography variant="h5" className="title">
            {title || ""}
          </Typography>
          <Breadcrumbs
            separator={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_9099_49293)">
                  <path
                    d="M6 12L10 8L6 4"
                    stroke="#5C5C5C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9099_49293">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            aria-label="breadcrumb"
          >
            {items.map((item) => {
              return (
                <>
                  {item.url ? (
                    <Link to={item.url}>{item.name}</Link>
                  ) : (
                    <Typography>{item.name}</Typography>
                  )}
                </>
              );
            })}
          </Breadcrumbs>
        </Box>
      </Box>
      <Box>
        <Stack spacing={1}>{extras}</Stack>
      </Box>
    </HeaderWrapper>
  );
};
const HeaderDetailsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin-block: 16px;
  border-radius: 8px;
  padding: 8px;
  .br {
    border-right: 1px solid #d3d3d3;
    &.noBr {
      border-right: none;
    }
  }
  hr {
    border-color: #d3d3d3;
    margin-block: 8px;
    width: 100%;
  }
  .g-label {
    font-size: 12px;
    font-weight: normal;
    color: #5c5c5c;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: #080808;
  }
  .w16 {
    width: 16.666666667%;
  }
  .w20 {
    width: 20%;
  }
  .w25 {
    width: 25%;
  }
  .w33 {
    width: 33%;
  }
  .w40 {
    width: 40%;
  }
  ,
  .w50 {
    width: 50%;
  }
  ,
  .elips {
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ,
  .p12 {
    padding: 12px;
  }
`;

export const HeaderDetails = ({ items = [] }) => {
  return (
    <HeaderDetailsWrapper style={{ width: "100%" }}>
      {items.map((item) => {
        if (item.isDivider) {
          return <hr />;
        }
        return (
          <div className={`${item.width} br ${item.noBr ? "noBr" : ""} p12`}>
            <div className="g-label">{item.label}</div>
            <p title={item.value} className={`${item.isEllips ? "elips" : ""}`}>
              {item.value || "-"}
            </p>
          </div>
        );
      })}
    </HeaderDetailsWrapper>
  );
};

const ImageWrapper = styled("div")`
  width: 15%;
  height: auto;
  margin-block: 16px;
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  margin-left: 15px;
  text-align: center;
  img {
    display: block;
    margin: auto;
    object-fit: contain;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    max-width: 70%;
    max-height: 90%;
  };
 svg {
  width: 70%;
  height: 100%;
  object-fit: contain;
    }
`;
export const ImageContiner = ({ text, image, isUser }) => {
  return (
    <ImageWrapper>
      <DynamicSVG text={text} imgUrl={image} isUser={isUser} />
      {/* <img src={image} alt="banner image" /> */}
    </ImageWrapper>
  );
};

const QRContainer = styled("div")`
    flex: 1 1 calc(25% - 24px);
    min-width: 200px;
    border-left: 1px solid #E0E0E0,
    border-radius: 10px,
    padding: 8px,
    background: '#fff,
    img {
        display: block;
        margin: auto;
        max-width: 100%;
        object-fit: contain;
    }
`;
export const QRCodeViewer = ({ data = {} }) => {
  return (
    <QRContainer>
        <Typography
          variant="h6"
          style={{ fontSize: "20px", fontWeight: 700, color: "#5C5C5C", flexDirection: 'start' }}
        >
          {'Other Information'}
        </Typography>
      <Box
        style={{ marginBottom: 0, flexDirection: 'column', alignItems: 'flex-start' }}
        display="flex"
        alignItems="center"
        mb={2}
      >
        <img src={data.download_url} alt="banner image" />
        <FormControlLabel style={{ background: "#F6F6F6", width: '100%', padding: '0 5px' }} control={<Checkbox checked={data.allow_contractor_docs} disabled />} label="Allow sub-contractor docs upload" />
        <FormControlLabel style={{ background: "#F6F6F6", width: '100%', padding: '0 5px' }} control={<Checkbox checked={data.share_company_docs} disabled />} label="Allow company docs to be shared" />
        <p style={{ color: '#5C5C5C', fontSize: '12px', marginTop: '10px' }}>Notify on completion of forms</p>
        <div style={{ color: '#080808', fontSize: '14px' }}>jacobjones@safetyloop.com</div>
      </Box>
    </QRContainer>
  );
};

export default ViewPageHeader;
