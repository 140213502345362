import React, { useEffect, useMemo, useState } from "react";
import useFilterChange from "../../../shared/components/useFilterChange";
import ActiveFilters from "../../../shared/components/activeFilters";
import CustomTable, { ActionMenu, CustomPagination, HeaderItem, TagRender } from "../../../shared/components/table";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Modal,
  TableCell,
  TableRow,
  TextField,
  styled
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import useDelete from "../../../shared/components/useDelete";
import InputSearchFilter from "../../../shared/components/InputSearchFilter";
import BulkAssignJobProfile from "./bulkAssignJobProfile";
import { UserGroupIcons } from "../../../shared/components/userGroupIcons";
import { Link } from "react-router-dom";
import { showToast } from "../../../shared/components/showNotification";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";
import Loader from "../../../shared/components/loader";

const TableWrapper = styled('div')`
  .users_active_first_name,
  .users_active_last_name,
  .users_active_employee_id,
  .users_active_username,
  .users_active_job,
  .users_active_group,
  .users_active_last_login,
  .users_active_last_modified,
  .users_active_notes {
    &.tc_show {
      display: table-cell;
    }
    &.hide {
      display: none;
    }
  }
`;

const columnSettingsOptions = [
  {value: 'users_active_first_name', label: 'First Name'},
  {value: 'users_active_last_name', label: 'Last Name'},
  {value: 'users_active_employee_id', label: 'Employee Id'},
  {value: 'users_active_username', label: 'User Name'},
  {value: 'users_active_job', label: 'Job Profile'},
  {value: 'users_active_group', label: 'Groups'},
  {value: 'users_active_last_login', label: 'Last Login'}
]

const AssignJobProfile = ({ selectedRows, setSelectedRows, setOpen }) => {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M3.75 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5Z" fill="white" />
          <path d="M16.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V16.6667C2 17.0203 2.14048 17.3594 2.39052 17.6095C2.64057 17.8595 2.97971 18 3.33333 18H16.6667C17.0203 18 17.3594 17.8595 17.6095 17.6095C17.8595 17.3594 18 17.0203 18 16.6667V3.33333C18 2.97971 17.8595 2.64057 17.6095 2.39052C17.3594 2.14048 17.0203 2 16.6667 2ZM14 11.064H6V8.9058H14V11.064Z" fill="#407797" />
        </svg>
        <span>Selected Users</span>
        <span>{selectedRows.length}</span>
      </div>
      <Button onClick={() => setOpen(true)} size="small" variant="outlined" style={{ color: '#407797', backgroundColor: '#FFFFFF', borderColor: '#407797', textTransform: 'none' }}>Assign Job Profile</Button>
      <Button size="small" variant="text" style={{ textTransform: 'none', color: '#FF3030', textDecoration: 'underline' }} onClick={() => setSelectedRows([])}>Cancel</Button>
    </div>
  )
}

const UsersList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  baseData,
  pageCount,
  setPageCount
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching
  } = useFetch(
    "/api/v2/entity_management/users?activated_eq=true",
    { filters: filterData, pagination: { page: filterState.page, limit: filterState.limit }, sort: filterState.sort }
  );
  const columnSetting = localStorage.getItem('usersField') || '{}';
  const parsedColumnSetting = JSON.parse(columnSetting);
  let settingsData = {
    "users_active_first_name": true,
    "users_active_last_name": true,
    "users_active_employee_id": true,
    "users_active_username": true,
    "users_active_job": true,
    "users_active_group": true,
    "users_active_last_login": true,
    "users_active_last_modified": true,
    "users_active_notes": true
  }
  if (Object.keys(parsedColumnSetting).length) {
    settingsData = parsedColumnSetting;
  } else {
    localStorage.setItem('usersField', JSON.stringify(settingsData));
  }
  const { mutate, isLoading: isDeleting } = useDelete('/api/v2/entity_management/users');
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [columnSettings, setColumnSettings] = useState(settingsData)
  const { data = [], metadata = {} } = responseData || {};

  useEffect(() => {
    metadata.count && setPageCount({ulist: {
      user_list: metadata.count || pageCount.ulist.user_list,
      arch: pageCount.ulist.arch
    }})
  }, [metadata.count]);

  const {
    first_names = [],
    last_names = [],
    employee_ids = [],
    usernames = [],
    groups = [],
    job_profiles = [],
    permissions: {
      allow_delete = true,
      allow_edit = true,
      allow_assign_job_profile = true,
      allow_view = true
    } = {}
  } = baseData || {};

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  }
  useEffect(() => {
    if (!isLoading) {
      callRefetch();
    }
  }, [filterState]);

  const groupsFilterData = [
    { label: "All", value: "All" },
    ...groups.map((e) => ({ ...e, label: e.key, value: e.value })),
  ];

  const handleEdit = (row, setClose) => {
    setEdit(row.id);
    if (setClose) {
      setClose();
    }
  };
  const handleDelete = (row, setClose) => {
    const payload = {
      id: row.id
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: 'User Deactivated Successfully' });        
        callRefetch();
      },
      onError: (e) => {
        showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
      } 
    });
    if (setClose) {
      setClose();
    }
  };

  const handleSelectedRows = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows(prev => ([...prev, id]));
    } else {
      const newValues = selectedRows.filter(e => e !== id);
      setSelectedRows(newValues);
    }
  }

  const headers = [
    <TableCell className={`left users_active_first_name ${columnSettings.users_active_first_name ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        title="First Name"
        isSort
        handleSort={() => handleSort("first_name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={filterData["first_name_cont"] || "All"}
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || "All", "first_name_cont")
            }
            options={[
              { label: "All", value: "All" },
              ...first_names.map((e) => ({ ...e, label: e.key || '', id: e.value })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_last_name ${columnSettings.users_active_last_name ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        title="Last Name"
        isSort
        handleSort={() => handleSort("last_name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={filterData["last_name_cont"] || "All"}
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || "All", "last_name_cont")
            }
            options={[
              { label: "All", value: "All" },
              ...last_names.map((e) => ({ ...e, label: e.key || '', id: e.value })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_employee_id ${columnSettings.users_active_employee_id ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        title="Employee Id"
        isSort
        handleSort={() => handleSort("employee_id")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={filterData["employee_id_cont"] || "All"}
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || "All", "employee_id_cont")
            }
            options={[
              { label: "All", value: "All" },
              ...employee_ids.map((e) => ({ ...e, label: e.key || '', id: e.value })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_username ${columnSettings.users_active_username ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        title="User Name"
        isSort
        handleSort={() => handleSort("username")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            value={filterData["username_cont"] || "All"}
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v.value || "All", "username_cont")
            }
            options={[
              { label: "All", value: "All" },
              ...usernames.map((e) => ({ ...e, label: e.key || '', id: e.value })),
            ]}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: 'none' }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_job ${columnSettings.users_active_job ? 'tc_show' : 'hide'}`} style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Job Profile"
        // isSort
        // handleSort={() => handleSort("job_profiles_name")}
        filter={
          <InputSearchFilter name="job_profiles_name_cont" filterData={filterData} handleOnFilterChange={handleOnFilterChange} filterState={filterState} />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_group ${columnSettings.users_active_group ? 'tc_show' : 'hide'}`} style={{ minWidth: '275px' }}>
      <HeaderItem
        title="Groups / Project Sites"
        // isSort
        // handleSort={() => handleSort("group_name_cont")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            value={
              groupsFilterData.find(
                (e) => e.value === filterData["group_id_eq"]
              ) || "All"
            }
            isOptionEqualToValue={(option, value) => option.value === value}
            disableClearable
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "group_id_eq")
            }
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: 'none' }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className={`users_active_last_login ${columnSettings.users_active_last_login ? 'tc_show' : 'hide'}`}>
      <HeaderItem
        title="Last Login"
        isSort
        handleSort={() => handleSort("last_sign_in_at")}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: '100px' }}>
      <HeaderItem
        title="Actions"
      />
    </TableCell>,
  ];

  const rows = data.map((ele, index) => {
        const {
          first_name,
          last_name,
          email,
          employee_id,
          username,
          job_profile_names = [],
          group_names = [],
          last_sign_in_at,
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell className={`left users_active_first_name ${columnSettings.users_active_first_name ? 'tc_show' : 'hide'}`}>
              <div className="flex" style={{alignItems:'center'}}>
                {allow_assign_job_profile && <Checkbox style={{ color: '#407797' }} checked={selectedRows.includes(ele.id)} onChange={handleSelectedRows(ele.id)} />}
                <div className="flex flex-c">
                <Link to={`/user_group/view/${ele.id}`}>
                  <div style={{ color: '#080808', fontWeight: '700', textDecoration: 'underline', cursor: 'pointer' }}>{first_name}</div>
                </Link>
                  { email && <div className="desc elips150" style={{color: '#909090', textDecoration: 'none', fontSize: '12px', fontWeight: '400'}} title={email}>
                    {email || ''}
                  </div>
                  }
                </div>
              </div>
            </TableCell>
            <TableCell className={`users_active_last_name ${columnSettings.users_active_last_name ? 'tc_show' : 'hide'}`}>{last_name}</TableCell>
            <TableCell className={`users_active_employee_id ${columnSettings.users_active_employee_id ? 'tc_show' : 'hide'}`}>{employee_id}</TableCell>
            <TableCell className={`users_active_username ${columnSettings.users_active_username ? 'tc_show' : 'hide'}`}>{username}</TableCell>
            <TableCell className={`users_active_job ${columnSettings.users_active_job ? 'tc_show' : 'hide'}`} style={{ minWidth: '275px' }}>
              <TagRender data={job_profile_names} />
            </TableCell>
            <TableCell className={`users_active_group ${columnSettings.users_active_group ? 'tc_show' : 'hide'}`} style={{ minWidth: '275px' }}>
              <TagRender data={group_names} />
            </TableCell>
            <TableCell className={`users_active_last_login ${columnSettings.users_active_last_login ? 'tc_show' : 'hide'}`}>{last_sign_in_at}</TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
            <ActionMenu>
                {(setClose) => {
                  const menuItems = [];
                  if (allow_view) {
                    menuItems.push(<MenuItem>
                      <Link to={`/user_group/view/${ele.id}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {UserGroupIcons.view}{" "}
                          <span style={{ paddingLeft: 5, color: "#080808" }}>
                            View
                          </span>
                        </Box>
                      </Link>
                    </MenuItem>);
                  }
                  if (allow_edit) {
                    menuItems.push(<MenuItem onClick={() => handleEdit(ele, setClose)}>
                    <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {UserGroupIcons.edit}{" "}
                        <span style={{ paddingLeft: 5, color: "#080808" }}>
                          Edit
                        </span>
                      </Box>
                  </MenuItem>);
                  }
                  if (allow_delete) {
                    menuItems.push(<MenuItem onClick={() => handleDelete(ele, setClose)}>
                    <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {UserGroupIcons.archive}{" "}
                        <span style={{ paddingLeft: 5, color: "#080808" }}>
                          Delete
                        </span>
                      </Box>
                  </MenuItem>)
                  }
                  return menuItems;
                }}
              </ActionMenu>
            </TableCell>
          </TableRow>
        );
      });

      if(isLoading){
        return <Loader />
      }

  return (
    <TableWrapper>
      <CustomTable
        headers={headers}
        rows={rows}
        columnSettingsHandler={setColumnSettings}
        columnSettingsState={columnSettings}
        columnSettingsOptions={columnSettingsOptions}
        columnSettingsName='usersField'
        enableColumnSettings
        renderActiveFilter={
          <>
            {selectedRows.length === 0 ? <ActiveFilters filterData={filterState} update={setFilterState} keyName='ulist' /> : <AssignJobProfile setOpen={setOpen} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />}
          </>
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={
            (_, nextPage) => {
              setFilterState((prev) => ({ ...prev, page: nextPage }))
            }
          }
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange = {
            (targetValue) => {
              setFilterState((prev) => ({
                ...prev,
                limit: parseInt(targetValue, 10),
                page: 1,
              }))
            }
          }
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BulkAssignJobProfile setSelectedRows={setSelectedRows} selectedUsers={selectedRows} onCancel={() => setOpen(false)} refetch={callRefetch} />
      </Modal>
    </TableWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
