import React, { useEffect, useState } from "react";
import { TableContainer, Paper, Button } from "@mui/material";
import { toast } from "react-toastify";
import axios from "@config/axiosConfig";
import Application from "@config/ApplicationWrapper";

import Filter from "@assets/images/Filter.png";
import Unstar from "@assets/fonts/unstar.svg";
import Stared from "@assets/fonts/stared.svg";
import EditBtn from "@assets/fonts/edit.svg";
import URLCopy from "@assets/fonts/File Link Copy.svg";
import DocLink from "@assets/fonts/Duplicate.svg";
import Zip from "@assets/fonts/Download.svg";
import DeleteBtn from "@assets/fonts/Trash.svg";
import EmptyTable from "@assets/images/empty-assets.svg";

import Folder from "@assets/fonts/Folder.svg";
import FolderExpired from "@assets/fonts/FolderExpired.svg";

import PNGIcon from "@assets/fonts/png.png";
import JPGIcon from "@assets/fonts/jpg.png";
import MP4Icon from "@assets/fonts/mp4.png";
import MP3Icon from "@assets/fonts/mp3.png";
import ZIPIcon from "@assets/fonts/zip.png";
import PPTIcon from "@assets/fonts/ppt.png";
import XLSIcon from "@assets/fonts/xls.png";
import PDFIcon from "@assets/fonts/pdf.png";
import DOCSIcon from "@assets/fonts/docs.png";
import XLSXIcon from "@assets/fonts/xlsx1.png";
import StartInspection from "../popups/start_inspection";

import ViewDocs from "../popups/view";
import Swal from "sweetalert2";
import { User } from "pages/HomePage/Login";
import { uploadFiles } from '../../../../utils/bulkUploadLogic';

const ListView = ({
                    pageDetails,
                    deleteFolder,
                    changeDirectory,
                    edit,
                    duplicate,
                    zip,
                    changeSorting,
                    isStarred,
                    stared,
                    searching,
                    searchByName,
                    searchByType,
                    searchByCreatedBy,
                    searchByUpdatedAt,
                    update,
                    findAsset,
                    breadcrumb,
                    filteredDocNameChange,
                    filteredDocName,
                    searchByGroup,
                    searchByGlobalGroup,
                    recoverDocument, // Added recoverDocument prop
                    isDeleted // Added isDeleted prop to distinguish deleted documents
                  }) => {
  const [sorting, setSorting] = useState(false);
  const [sortedBy, setSortedBy] = useState("doc_name");
  const imageFormat = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  const pdfFormat = /(\.pdf)$/i;
  const [openview, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [currentFolderDocuments, setCurrentFolderDocuments] =
      useState(pageDetails);
  const [filteredDocuments, setFilteredDocuments] = useState(pageDetails);
  const [groupsList, setGroupsList] = useState([]);
  const [openStartInspection, setOpenStartInspection] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    setCurrentFolderDocuments(pageDetails);
    setFilteredDocuments(pageDetails);
    fetchGroup();
  }, [pageDetails]);

  const fetchGroup = async () => {
    const groupDetails = await axios.get(
        `${Application.api_url}/api/v2/common/groups?type=PublicGroup`
    );
    if (groupDetails.status == 200 && groupDetails.data.success === true) {
      var result = groupDetails.data;
      setGroupsList(result.data);
    }
  };

  const filterDocuments = (type, value) => {
    let filteredData;
    if (type === "name") {
      filteredData = currentFolderDocuments.filter((doc) =>
          doc.name.toLowerCase().includes(value.toLowerCase())
      );
      filteredDocNameChange(value);
    } else if (type === "type") {
      filteredData = currentFolderDocuments.filter(
          (doc) => doc.type.toLowerCase() === value.toLowerCase()
      );
    } else if (type === "created_by") {
      filteredData = currentFolderDocuments.filter((doc) =>
          doc.created_by.toLowerCase().includes(value.toLowerCase())
      );
    } else if (type === "updated_at") {
      filteredData = currentFolderDocuments.filter(
          (doc) =>
              new Date(doc.updated_at).toDateString() ===
              new Date(value).toDateString()
      );
    }
    setFilteredDocuments(filteredData);
  };

  const handleDirectoryChange = (folder) => {
    changeDirectory(folder);
    setCurrentFolderDocuments(folder.documents);
    setFilteredDocuments(folder.documents);
  };

  const NoAssetsFound = () => (
      <div className="no-assets" style={{ textAlign: "center" }}>
        <img src={EmptyTable} />
        <p>
          <b>No Data</b>
        </p>
      </div>
  );

  const hanldeClick = async (val) => {
    findAsset(val.is_asset_doc || val.is_internal_doc);
    if (val.is_directory) {
      changeDirectory(val);
    } else {
      const data = await axios.get(
          `${Application.api_url}/api/v2/company_documents/${val.slug}?deleted_at_null=true`
      );
      if (data.status == 200 && data.data.success === true) {
        let values = data.data.data;
        if (values.file && values.file.url) {
          window.open(values.file.url, "_blank");
        }
      }
    }
  };

  const fileIcons = {
    Folder: Folder,
    FolderExpired: FolderExpired,
    png: PNGIcon,
    jpg: JPGIcon,
    jpeg: JPGIcon,
    pdf: PDFIcon,
    doc: DOCSIcon,
    docx: DOCSIcon,
    xlsx: XLSXIcon,
    xls: XLSIcon,
    csv: XLSIcon,
    mp4: MP4Icon,
    mp3: MP3Icon,
    zip: ZIPIcon,
    ppt: PPTIcon,
    pptx: PPTIcon,
  };

  const FileType = ({ item }) => {
    let fileExtension = item.is_directory
        ? "Folder"
        : (item.name || "").split(".").pop().toLowerCase();
    let fileIcon = fileIcons[fileExtension] || DOCSIcon;
    if (item.is_directory && item.expired) fileIcon = fileIcons["FolderExpired"];

    return (
        <img
            src={fileIcon}
            alt="image"
            className="file-icon"
            style={{ width: "40px", marginRight: "10px" }}
        />
    );
  };

  const onDragStart = (event, id) => {
    event.dataTransfer.setData("id", id);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = async (event, targetId) => {
    const draggedId = event.dataTransfer.getData("id");
    const draggedItem = pageDetails.find((item) => item.int_id == draggedId);
    const targetItem = pageDetails.find((item) => item.int_id == targetId);

    const formData = new FormData();
    formData.append("doc_detail[parent_id]", targetItem.int_id);
    if (
        targetId &&
        draggedId &&
        targetItem.is_directory &&
        !draggedItem.is_asset_doc &&
        !targetItem.is_asset_doc &&
        !draggedItem.is_internal_doc &&
        !targetItem.is_internal_doc &&
        targetItem.slug !== draggedItem.slug
    ) {
      const result = await axios({
        method: "PATCH",
        url: `${Application.api_url}/api/v2/company_documents/${draggedItem.slug}`,
        data: formData,
      });

      if (result && result.status == 200) {
        toast.success("Moved Successfully");
        update(Date.now());
      }
    }
  };

  const handleDeleteClick = async (deleteFolder, val) => {
    let doc_type = val.is_directory ? "Folder" : "File";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this ${doc_type}?`,
      showCancelButton: true,
      confirmButtonColor: "#345F79",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFolder(val.slug);
        Swal.fire("Deleted!", `Your ${doc_type} has been deleted.`, "success");
      }
    });
  };

  function ChipDesign({ data, type }) {
    let finalData = data.filter(Boolean);
    if (data && data.length >= 3) {
      finalData = finalData.slice(0, 2);
    }
    return (
        <div>
          {finalData &&
              finalData.map((obj, index) => (
                  <span key={index} className={`badge badge-light responsible-party`}>
              {obj && obj["name"]}
            </span>
              ))}
          {data && data.length >= 3 && (
              <span className={`badge badge-light responsible-party more-groups`}>
            + {data.length - 2} More Groups
          </span>
          )}
        </div>
    );
  }

  function handleGroupSearch(val) {
    if (val == "global_project") {
      searching("globalGroup", val);
    } else if (val != "") {
      searching("group", val);
    } else {
      searching("removeGroup", "");
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    console.log("drag over");
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const isFileDrop = Array.from(event.dataTransfer.items).some(item => item.kind === 'file');

      if (isFileDrop) {
        const files = Array.from(event.dataTransfer.items);
        uploadFiles(files, getCurrentParentId());
        setIsDragOver(false);
        return;
      }
    }
  
    setIsDragOver(false);
  }

  const handleTrashDrop = async (event) => {
    event.preventDefault();
    const draggedId = event.dataTransfer.getData("id");
    if (!draggedId) return;

    const confirmDelete = window.confirm(
        "Are you sure you want to delete this item?"
    );
    if (!confirmDelete) return;

    try {
      console.log("Dragged ID:", draggedId);
      debugger;
      const response = await axios.patch(

          `${Application.api_url}/api/v2/company_documents/${draggedId}/soft_delete`,
          { deleted_at: new Date() } // Assuming the backend handles the soft-delete using this field
      );

      console.log("Delete response:", response.data);

    } catch (e) {
      console.error(e);
    }
  };

  const getFilesDataTransferItems = (dataTransferItems) => {
    function traverseFileTreePromise(item, path = "", folder) {
      return new Promise((resolve) => {
        if (item.isFile) {
          item.file((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              folder.push({
                file_path: reader.result,
                file_name: file.name,
                is_internal_doc: true,
              });
              resolve(file);
            };
            reader.readAsDataURL(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries((entries) => {
            let entriesPromises = [];
            let sub_folder = [];
            folder.push({
              folder_name: item.name,
              is_internal_doc: true,
              sub_folder: sub_folder,
            });

            for (const entry of entries)
              entriesPromises.push(
                  traverseFileTreePromise(
                      entry,
                      path || "" + item.name + "/",
                      sub_folder
                  )
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }

    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
            traverseFileTreePromise(it.webkitGetAsEntry(), null, files)
        );
      Promise.all(entriesPromises).then((entries) => {
        resolve(files);
      });
    });
  };

  const appendFilesToFormData = (files, formData, parentKey = "") => {
    files.forEach((file, index) => {
      let key = `${parentKey}[${index}]`;
      if (file.file_path) {
        formData.append(`${key}[file_path]`, file.file_path);
        formData.append(`${key}[file_name]`, file.file_name);
      } else {
        formData.append(`${key}[folder_name]`, file.folder_name);
        if (file.sub_folder) {
          appendFilesToFormData(file.sub_folder, formData, `${key}[sub_folder]`);
        }
      }
    });
  };

  const uploadFilesAndFolders = (dragFiles) => {
    const data = new FormData();
    appendFilesToFormData(dragFiles, data, "files");
    data.append("is_drag_folder", true);
    data.append("parent_id", getCurrentParentId());

    axios
        .patch(`/company_documents/${User.companyInfo.id}/upload`, data)
        .then((response) => {
          toast.success("Files successfully uploaded.");
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const getCurrentParentId = () => {
    const lastItem = breadcrumb[breadcrumb.length - 1];
    return lastItem ? lastItem.int_id : null;
  };

  return (
      <TableContainer
          component={Paper}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDrop={(e) => handleDrop(e)}
      >
        <table
            className={`table table-striped dragable-table ${
                isDragOver ? "on-drag" : ""
            }`}
        >
          <thead>
          <tr style={{ height: "60px" }} className="risk-assessment-divider">
            <th scope="col" className="risk-assessment-divider">
              {!isStarred && !isDeleted && (
                  <div
                      className="d-flex justify-content-between align-item-center"
                      onClick={(e) => {
                        let sort = sortedBy === "starred" ? !sorting : true;
                        setSorting(sort);
                        setSortedBy("starred");
                        changeSorting(sort, "sort_by_starred");
                      }}
                  >
                    <img
                        src={sortedBy === "starred" && sorting ? Stared : Unstar}
                        alt=""
                        className="cursor-pointer"
                    />
                  </div>
              )}
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                  className="d-flex justify-content-between align-item-center"
                  onClick={(e) => {
                    let sort = sortedBy == "name" ? !sorting : true;
                    setSorting(sort);
                    setSortedBy("name");
                    changeSorting(sort, "doc_name");
                  }}
              >
                Name
                <img
                    src={Filter}
                    alt=""
                    className={sortedBy == "name" && sorting ? "up-arrow" : ""}
                />
              </div>
              <div className="input-group">
                <input
                    type="text"
                    className="form-control assessment-name"
                    id="searchByName"
                    placeholder="Search"
                    aria-label="name"
                    name="searchByName"
                    value={filteredDocName || searchByName}
                    onChange={(e) => {
                      breadcrumb.length > 1
                          ? filterDocuments("name", e.target.value)
                          : searching("name", e.target.value);
                    }}
                />
                <div className="input-group-addon">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                  className="d-flex justify-content-between align-item-center"
                  onClick={(e) => {
                    let sort = sortedBy == "type" ? !sorting : true;
                    setSorting(sort);
                    setSortedBy("type");
                    changeSorting(sort, "doc_type");
                  }}
              >
                Type
                <img
                    src={Filter}
                    alt=""
                    className={sortedBy == "type" && sorting ? "up-arrow" : ""}
                />
              </div>
              <div className="input-group">
                <select
                    className="form-control risk-type"
                    onChange={(e) => {
                      searching("type", e.target.value);
                    }}
                    value={searchByType}
                >
                  <option value="">All</option>
                  <option value="folder">Folder</option>
                  <option value="file">File</option>
                </select>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                  className="d-flex justify-content-between align-item-center"
                  onClick={(e) => {
                    let sort = sortedBy == "type" ? !sorting : true;
                    setSorting(sort);
                    setSortedBy("type");
                    changeSorting(sort, "doc_type");
                  }}
              >
                Group/Global project
                <img
                    src={Filter}
                    alt=""
                    className={sortedBy == "type" && sorting ? "up-arrow" : ""}
                />
              </div>
              <div className="input-group">
                <select
                    className="form-control risk-type"
                    onChange={(e) => {
                      handleGroupSearch(e.target.value);
                    }}
                    value={
                      searchByGlobalGroup ? "global_project" : searchByGroup
                    }
                >
                  <option value="">All</option>
                  <option value="global_project">Global project</option>
                  {groupsList.map((val, index) => (
                      <option key={index} value={val.name}>{val.name}</option>
                  ))}
                </select>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                  className="d-flex justify-content-between align-item-center"
                  onClick={(e) => {
                    let sort = sortedBy == "created_by" ? !sorting : true;
                    setSorting(sort);
                    setSortedBy("created_by");
                    changeSorting(sort, "created_by");
                  }}
              >
                Created By
                <img
                    src={Filter}
                    alt=""
                    className={
                      sortedBy == "created_by" && sorting ? "up-arrow" : ""
                    }
                />
              </div>
              <div className="input-group">
                <input
                    type="text"
                    className="form-control assessment-name"
                    placeholder="Search"
                    aria-label="group"
                    id="created_by"
                    aria-describedby="basic-addon1"
                    name="created_by"
                    onChange={(e) => {
                      searching("created_by", e.target.value);
                    }}
                    value={searchByCreatedBy}
                />
                <div className="input-group-addon">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                  className="d-flex justify-content-between align-item-center"
                  onClick={(e) => {
                    let sort = sortedBy == "updated_at" ? !sorting : true;
                    setSorting(sort);
                    setSortedBy("updated_at");
                    changeSorting(sort, "updated_at");
                  }}
              >
                Updated At
                <img
                    src={Filter}
                    alt=""
                    className={
                      sortedBy == "updated_at" && sorting ? "up-arrow" : ""
                    }
                />
              </div>
              <div className="input-group">
                <input
                    type="date"
                    className="form-control assessment-name"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="updated_at"
                    value={searchByUpdatedAt}
                    onChange={(e) => {
                      searching("updated_at", e.target.value);
                    }}
                />
              </div>
            </th>
            <th scope="col" className="table-action">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          {filteredDocuments && filteredDocuments.length > 0 ? (
              filteredDocuments.map((val) => (
                  <tr
                      key={val.int_id}
                      onDragOver={(e) => onDragOver(e)}
                      onDrop={(e) => onDrop(e, val.int_id)}
                  >
                    <td
                        className={
                          val.is_asset_doc != true && val.is_internal_doc != true
                              ? "ui-droppable"
                              : ""
                        }
                    >
                      {!isDeleted && (
                          <img
                              src={val.is_starred ? Stared : Unstar}
                              alt=""
                              className="cursor-pointer"
                              onClick={(e) => {
                                stared(val.is_starred, val.slug);
                              }}
                          />
                      )}
                    </td>
                    <td
                        className="alerts-name drag"
                        style={{ position: "relative" }}
                        draggable="true"
                        onDragStart={(e) => onDragStart(e, val.slug)}
                    >
                      <p
                          onClick={(e) => {
                            hanldeClick(val);
                          }}
                          className={`pf-name-list ${
                              val.expired ? "folder-expired" : ""
                          }`}
                      >
                        <FileType item={val} />
                        {val.name}
                      </p>
                    </td>
                    <td className="ui-droppable">
                      {val.is_directory ? "Folder" : "File"}
                    </td>
                    <td className="ui-droppable">
                      <ChipDesign data={val.group_details} type="users" />
                    </td>
                    <td className="ui-droppable">
                      <p className="action-by">{val.created_by}</p>
                      <p className="action-time">{val.created_at}</p>
                    </td>
                    <td className="ui-droppable">{val.updated_at}</td>
                    <td
                        className="template-options ui-droppable"
                        style={{ textAlign: "center" }}
                    >
                      {!isDeleted && val.is_asset_doc != true && val.is_internal_doc != true && (
                          <>
                            <a
                                aria-expanded="false"
                                aria-haspopup="true"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                                id="dropdownMenuLink"
                                role="button"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <ul
                                aria-labelledby="dropdownMenuLink"
                                className="dropdown-menu"
                            >
                              <li>
                                <a
                                    className="archive_group_invites"
                                    href="#"
                                    title="Delete"
                                >
                                  <div className="row d-flex">
                                    <div className="col-sm-3">
                                      <img
                                          src={DeleteBtn}
                                          className="cursor-pointer"
                                      />
                                    </div>
                                    <div
                                        className="col-sm-9"
                                        style={{
                                          paddingTop: "1px",
                                          paddingLeft: "21px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteClick(deleteFolder, val);
                                        }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                </a>
                                <a
                                    className="archive_group_invites"
                                    href="#"
                                    title="Edit"
                                >
                                  <div className="row d-flex">
                                    <div className="col-sm-3">
                                      <img src={EditBtn} className="cursor-pointer" />
                                    </div>
                                    <div
                                        className="col-sm-9"
                                        style={{
                                          paddingTop: "1px",
                                          paddingLeft: "21px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          edit(val);
                                        }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                </a>
                                <a
                                    className="archive_group_invites"
                                    href="#"
                                    title="Copy Link"
                                >
                                  <div className="row d-flex">
                                    <div className="col-sm-3">
                                      <img
                                          src={URLCopy}
                                          className="cursor-pointer"
                                      />
                                    </div>
                                    <div
                                        className="col-sm-9"
                                        style={{
                                          paddingTop: "1px",
                                          paddingLeft: "21px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          navigator.clipboard.writeText(
                                              `${window.location.origin}/company_documents/${val.slug}`
                                          );
                                        }}
                                    >
                                      Copy Link
                                    </div>
                                  </div>
                                </a>
                                <a
                                    className="archive_group_invites"
                                    href="#"
                                    title="Duplicate"
                                >
                                  <div className="row d-flex">
                                    <div className="col-sm-3">
                                      <img src={DocLink} className="cursor-pointer" />
                                    </div>
                                    <div
                                        className="col-sm-9"
                                        style={{
                                          paddingTop: "1px",
                                          paddingLeft: "21px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          duplicate(val.slug);
                                        }}
                                    >
                                      Duplicate
                                    </div>
                                  </div>
                                </a>
                                <a
                                    className="archive_group_invites"
                                    href="#"
                                    title="Download"
                                >
                                  <div className="row d-flex">
                                    <div className="col-sm-3">
                                      <img src={Zip} className="cursor-pointer" />
                                    </div>
                                    <div
                                        className="col-sm-9"
                                        style={{
                                          paddingTop: "1px",
                                          paddingLeft: "21px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          zip(val.slug);
                                        }}
                                    >
                                      Download
                                    </div>
                                  </div>
                                </a>
                                {val.is_directory != true && (
                                    <a
                                        className="archive_group_invites"
                                        href="#"
                                        title="Start form from Document"
                                    >
                                      <div className="row d-flex">
                                        <div className="col-sm-3">
                                          <i className={"fa fa-plus-circle"}></i>
                                        </div>
                                        <div
                                            className="col-sm-9"
                                            style={{
                                              paddingTop: "1px",
                                              paddingLeft: "21px",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              setSelectedData(val);
                                              setOpenStartInspection(true);
                                            }}
                                        >
                                          Start a Form from Document
                                        </div>
                                      </div>
                                    </a>
                                )}
                              </li>
                            </ul>
                          </>
                      )}
                      {isDeleted && (
                          <Button
                              onClick={() => recoverDocument(val.slug)}
                              variant="contained"
                              color="primary"
                          >
                            Recover
                          </Button>
                      )}
                    </td>
                  </tr>
              ))
          ) : (
              <tr>
                <td colSpan="6">
                  <NoAssetsFound />
                </td>
              </tr>
          )}
          </tbody>
        </table>
        {selectedData && (
            <ViewDocs
                show={openview}
                details={selectedData}
                closepopup={(e) => {
                  setOpenView(false);
                }}
            />
        )}
        {selectedData && openStartInspection && (
            <StartInspection
                show={openStartInspection}
                details={selectedData}
                closepopup={(e) => {
                  setOpenStartInspection(false);
                }}
            />
        )}
      </TableContainer>
  );
};

export default ListView;
