import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  styled,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

export const AlertBoxContainer = styled(Box)({
  padding: "32px 60px",
  "h4, p": {
    fontFamily: 'Inter'
  },
  ".cmn-btn": {
    display: "flex",
    height: "42px",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
  },
  '.cancel, .cancel:hover': {
     background: '#ECECEC',
      color: '#5C5C5C'
  },
  '.archive-btn, .archive-btn:hover': {
    background: '#D92929',
    color: '#fff',
    borderRadius: '4px'
  }
});

export default function AlertDialog({ alertOpen, handleClose, handleOk, title = 'Archive Job Profile', description = 'Are You sure you want to archive this job profile?', okText = 'Archive', loading=false, icon, okButtonStyle = {} }) {
  return (
    <React.Fragment>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <AlertBoxContainer>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon || <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <path
                d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z"
                stroke="#D92929"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M37.5 22.5176L22.5 37.4827M22.5 22.5176L37.5 37.4827L22.5 22.5176Z"
                stroke="#D92929"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>}
            <Typography
              style={{
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                color: "#080808",
              }}
              variant="h4"
            >
              {title}
            </Typography>
            <DialogContentText
              style={{
                fontSize: "14px",
                lineHeight: "32px",
                fontWeight: 400,
                color: "#5c5c5c",
              }}
              id="alert-dialog-description"
            >
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button disabled={loading} className="cmn-btn cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} endIcon={loading ? <CircularProgress style={{color: '#fff'}} size={16} color="inherit" /> : null} className="cmn-btn archive-btn" onClick={handleOk} autoFocus style={{...okButtonStyle}}>
              {okText}
            </Button>
          </DialogActions>
        </AlertBoxContainer>
      </Dialog>
    </React.Fragment>
  );
}
