import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  styled,
  createTheme,
  ThemeProvider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { DualBoxContainer } from "../../../shared/components/dualListBox";
import useFetch from "../../../shared/components/userFetch";
import { ViewPageWrapper } from "../UserList/view";
import ViewPageHeader from "../../../shared/components/viewPageHeader";
import LisContainer from "./LisContainer";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, system-ui, -apple-system", // Change font family globally
  },
});

const ViewContainer = styled(Box)({
  padding: "32px 60px 20px",
  background: "#F6F6F6",
  boxShadow: "0px 6px 9px 0px rgba(0, 0, 0, 0.12)",
  ".back-arrow": {
    borderRadius: "4px",
    border: "1px solid #D3D3D3",
    display: "flex",
    width: "42px",
    height: "42px",
    padding: "2px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    background: "#fff",
    marginRight: "12px",
    a: {
      lineHeight: "normal",
    },
  },
  ".info-box": {
    boxShadow: "none",
    marginTop: 0,
    width: "100%",
    marginLeft: 0,
    ".MuiGrid-item": {
      paddingTop: "0",
    },
  },
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  //   padding: "16px",
  marginBottom: "20px",
  ".subtitles": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  ".breadcrumb-li": {
    color: "#407797",
    fontSize: "14px",
    fontWeight: "400",
    textDecoration: "underline",
    "&:active": {
      color: "#5C5C5C",
    },
  },
  ".current-page": {
    fontSize: "14px",
    fontWeight: "400",
    color: "#5c5c5c",
  },
  ".title": {
    fontSize: "32px",
    fontWeight: 700,
    color: "#080808",
  },
});

const JobProfileView = ({ match }) => {
  const id = match.params.id;

  const { data: viewData, loading: viewDataLoading } = useFetch(
    `/api/v2/entity_management/job_profiles/${id}?activated_eq=true`
  );
  const { data = {} } = viewData || {};

  const {
    jp_users = [],
    jp_topics = [],
    jp_form_templates = [],
    name = "",
  } = data;

  const users = jp_users.map(v => ({ ...v, key: v.name, value: v.id }));

  if (viewDataLoading) {
    return false;
  }
  return (
    <ThemeProvider theme={theme}>
      <ViewPageWrapper>
        <div className="header">
          <div className="v-container">
            <ViewPageHeader
              title={name || "-"}
              items={[
                { name: "Job Profiles List", url: "/user_group?tab=jp" },
                { name: "View Job Profile" },
              ]}
              backUrl="/user_group?tab=jp"
            />
          </div>
        </div>
        <DualBoxContainer>
          <div className="view-container">
            <Typography variant="h4" className="tab-header">
              Assigned
            </Typography>
            <Grid container spacing={2}>
              <LisContainer title={'Trainings'} data={jp_topics || []} />
              <LisContainer title={'Shared Forms'} data={jp_form_templates || []} />
              <LisContainer title={'Users'} data={users || []} />
            </Grid>
          </div>
        </DualBoxContainer>
      </ViewPageWrapper>
      {/* <ViewContainer>
        <Header>
          <Box>
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box sx={{ flexDirection: "column", display: "flex" }}>
                <div className="back-arrow">
                  <Link to={`/user-group`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M20.6666 6.66663L11.3333 16L20.6666 25.3333"
                        stroke="#080808"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
                <div>&nbsp;</div>
              </Box>
              <Box sx={{ flexDirection: "column", display: "flex" }}>
                <Typography variant="h5" className="title">
                  {name || ""}
                </Typography>

                <Typography className="subtitles" variant="subtitle1">
                  <Link to={`/user-group`} className="breadcrumb-li">
                    Job Profiles List
                  </Link>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_9200_15334)">
                      <path
                        d="M6 12L10 8L6 4"
                        stroke="#5C5C5C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_9200_15334">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  <span className="current-page">View Job Profile</span>
                </Typography>
              </Box>
            </div>
          </Box>
          <IconButton>
          </IconButton>
        </Header>
      </ViewContainer> */}
    </ThemeProvider>
  );
};

export default withRouter(JobProfileView);
