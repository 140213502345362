import React, { useEffect, useMemo, useState } from "react";
import ActiveFilters from "../../../shared/components/activeFilters";
import CustomTable, {
  HeaderItem,
  ActionMenu,
  TagRender,
  CustomPagination,
} from "../../../shared/components/table";
import {
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import useFetch from "../../../shared/components/userFetch";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import useDelete from "../../../shared/components/useDelete";
import { UserGroupIcons } from "../../../shared/components/userGroupIcons";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";
import Loader from "../../../shared/components/loader";
import { showToast } from "../../../shared/components/showNotification";

const JobProfileList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  pageCount,
  setPageCount
}) => {
  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching
  } = useFetch("/api/v2/entity_management/job_profiles?activated_eq=true", {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    "/api/v2/entity_management/job_profiles/base_data?activated_eq=true"
  );
  const { mutate, isLoading: isDeleting } = useDelete(
    "/api/v2/entity_management/job_profiles"
  );
  const { data = [], metadata = {} } = responseData || {};
  const {
    job_profiles = [],
    jp_form_templates = [],
    jp_trainings = [],
    jp_users = [],
    permissions: {
      allow_view = true,
      allow_edit = true,
      allow_delete = true
    } = {}
  } = baseData || {};


  useEffect(() => {
    metadata.count && setPageCount({jp: {
      job_profile: metadata.count || pageCount.jp.job_profile,
      arch: pageCount.jp.arch
    }})
  }, [metadata.count]);

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!isLoading) {
      refetch({
        filters: filterData,
        pagination: { page: filterState.page, limit: filterState.limit },
        sort: filterState.sort,
      });
    }
  }, [filterState]);

  const trainingFilterData = [
    { label: "All", value: "All" },
    ...jp_trainings.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];
  const profilesFilterData = [
    { label: "All", value: "All" },
    ...job_profiles.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];

  const usersFilterData = [
    { label: "All", value: "All" },
    ...jp_users.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];
  const templateFilterData = [
    { label: "All", value: "All" },
    ...jp_form_templates.map((e) => ({ ...e, label: e.key, value: e.key })),
  ];

  const handleEdit = (row, setClose) => {
    setEdit(row.id);
    if (setClose) {
      setClose();
    }
  };
  const handleDelete = (row, setClose) => {
    const payload = {
      id: row.id,
    };
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: 'Job Profile Deactivated Successfully' });        
        callRefetch();
      },
      onError: (e) => {
        showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
      } 
    });
    if (setClose) {
      setClose();
    }
  };

  const headers = [
    <TableCell className="left">
      <HeaderItem
        title="Job Profile Name"
        isSort
        handleSort={() => handleSort("name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || "All", "id_eq")}
            value={
              profilesFilterData.find((e) => e.value === filterData["id_eq"]) ||
              "All"
            }
            options={profilesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Trainings"
        // isSort
        // handleSort={() => handleSort("jp_topics_topics_for_training_id_eq")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(
                v || "All",
                "jp_topics_topics_for_training_id_eq"
              )
            }
            value={
              trainingFilterData.find(
                (e) =>
                  e.value === filterData["jp_topics_topics_for_training_id_eq"]
              ) || "All"
            }
            options={trainingFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
     <TableCell>
     <HeaderItem
       title="Shared Forms"
      //  isSort
      //  handleSort={() => handleSort("jp_form_templates_id_eq")}
       filter={
         <Autocomplete
           size="small"
           defaultValue="All"
           disableClearable
           isOptionEqualToValue={(option, value) => option.value === value}
           onChange={(_, v) =>
             handleOnFilterChange(v || "All", "jp_form_templates_id_eq")
           }
           value={
             templateFilterData.find(
               (e) => e.value === filterData["jp_form_templates_id_eq"]
             ) || "All"
           }
           options={templateFilterData}
           popupIcon={<img src={SearchIcon} />}
           sx={{ width: "100%", background: "#fff", border: "none" }}
           renderInput={(params) => <TextField {...params} label="Search" />}
         />
       }
     />
   </TableCell>,
    <TableCell>
      <HeaderItem
        title="Users"
        // isSort
        // handleSort={() => handleSort("jp_users_user_id")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "jp_users_user_id_eq")
            }
            value={
              usersFilterData.find(
                (e) => e.value === filterData["jp_users_user_id_eq"]
              ) || "All"
            }
            options={usersFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          name,
          jp_trainings = [],
          jp_users = [],
          jp_shared_forms = [],
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell className="left">
              <div className="flex">
                {/* <Checkbox style={{ color: '#407797' }} /> */}
                <div className="flex" style={{ alignItems: "center" }}>
                  <div className="flex flex-c">
                    <Link to={`/job_profile/view/${ele.id}`}>
                      <div style={{ color: '#080808', fontWeight: '700', textDecoration: 'underline', cursor: 'pointer' }}>{name}</div>
                    </Link>
                    </div>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <TagRender data={jp_trainings} />
            </TableCell>
            <TableCell>
              <TagRender data={jp_shared_forms} />
            </TableCell>
            <TableCell>
              <TagRender data={jp_users} />
            </TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
              <ActionMenu>
                {(setClose) => {
                  const menuItems = [];
                  if (allow_view) {
                    menuItems.push(<MenuItem onClick={() => console.log(ele, setClose)}>
                      <Link to={`/job_profile/view/${ele.id}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {UserGroupIcons.view}{" "}
                          <span style={{ paddingLeft: 5, color: "#080808" }}>
                            View
                          </span>
                        </Box>
                      </Link>
                    </MenuItem>)
                  }
                  if (allow_edit) {
                    menuItems.push(<MenuItem onClick={() => handleEdit(ele, setClose)}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {UserGroupIcons.edit}{" "}
                        <span style={{ paddingLeft: 5, color: "#080808" }}>
                          Edit Details
                        </span>
                      </Box>
                    </MenuItem>)
                  }
                  if (allow_delete) {
                    menuItems.push(<MenuItem onClick={() => handleDelete(ele, setClose)}>
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {UserGroupIcons.archive}{" "}
                        <span style={{ paddingLeft: 5, color: "#D92929" }}>
                          Archive
                        </span>
                      </Box>
                    </MenuItem>)
                  }
                  return menuItems;
                }}
              </ActionMenu>
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  if(isLoading){
    return <Loader />
  }

  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <ActiveFilters filterData={filterState} update={setFilterState} keyName='jp' />
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(JobProfileList);