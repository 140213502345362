import { User } from '../pages/HomePage/Login';
import { subscribeToUploadStatus } from '../channels/upload_status_channel';
import { toast } from "react-toastify";

function initializeUploadStatus(totalFiles) {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    let formData = new FormData();

    formData.append('total_files', totalFiles);
    formData.append('user_id', user.id);

    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'upload_statuses',
        type: 'POST',
        data: formData,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        processData: false,
        contentType: false,
        success: function (response) {
          User.currentBulkUploadId = response.data.upload_status_id;
          console.log('Upload status id:', response.data.upload_status_id);
          subscribeToUploadStatus(user.id, response.data.upload_status_id);
          resolve(response.data.upload_status_id);
        },
        error: function (error) {
          reject(error);
        }
      })
    })
  }

  export function uploadFiles(dataTransferItems, parentId, callback) {
    let files = [];
    let entriesPromises = [];
    let metadata = { count: 0 }
    for (let it of dataTransferItems) {
      entriesPromises.push(
        traverseFileTreePromise(it.webkitGetAsEntry(), '', files, metadata)
      );
    }
    let user = localStorage.getItem('user');
    user = JSON.parse(user);

    Promise.all(entriesPromises).then(() => {
      const totalFiles = metadata.count;
      initializeUploadStatus(totalFiles).then(uploadStatusId => {
        handleFileAndFolderUploads(files, parentId, uploadStatusId, totalFiles);
      })
    }).then(() => {
      toast.success("Upload is in progress!");
      setTimeout(() => {
        callback()
      }, 500)
    })
  }

  function handleFileAndFolderUploads(entries, parentId, uploadStatusId, totalFiles) {
    const uploadPromises = entries.map(entry => {
      if (entry.folder_name) {
        return createFolder(entry, parentId, uploadStatusId).then(response => {
          return handleFileAndFolderUploads(entry.sub_folder, response.parent_id, uploadStatusId, totalFiles);
        })
      } else if (entry.file_buffer) {
        const file = new File([entry.file_buffer], entry.file_name);

        return uploadFile(file, entry.relative_path, parentId, uploadStatusId, totalFiles)
      }
    })

    return Promise.all(uploadPromises);
  }

  function createFolder(entry, parentId, uploadStatusId) {
    return new Promise((resolve, reject) => {
      let user = localStorage.getItem('user');
      user = JSON.parse(user);
      const newParentId = parentId == 0 ? null : parentId;

      const folderFormData = new FormData();
      folderFormData.append('folder_name', entry.folder_name);
      folderFormData.append('relative_path', entry.relative_path);
      folderFormData.append('parent_id', newParentId);
      folderFormData.append('is_drag_folder', true);
      folderFormData.append('user_id', user.id)
      folderFormData.append('upload_status_id', uploadStatusId)

      $.ajax({
        url: `/company_documents/${user.company_id}/upload`,
        type: 'PATCH',
        data: folderFormData,
        processData: false,
        contentType: false,
        success: function(response) {
          console.log('Folder created: ', entry.folder_name);
          resolve(response);
        },
        error: function (error) {
          console.error('Error creating folder:', error);
          reject(error);
        }
      });
    })
  }

  function uploadFile(file, relativePath, parentId, uploadStatusId, totalFiles) {
    const totalSize = file.size;
    const concurrentUploads = 3
    let chunkStart = 0;
    let activeUploads = 0;
    const chunkSize = 1024 * 1024;

    function uploadNextChunk() {
      while (chunkStart < totalSize && activeUploads < concurrentUploads) {
        activeUploads++;
        const nextChunkSize = Math.min(chunkSize, totalSize - chunkStart);
        uploadChunk(file, chunkStart, nextChunkSize, totalSize, file.name, relativePath, parentId, uploadStatusId, totalFiles, () => {
          activeUploads--;
          uploadNextChunk();
        });
        chunkStart += nextChunkSize;
      }

      if (chunkStart >= totalSize && activeUploads === 0) {
        // console.log(file)
      }
    }

    uploadNextChunk();
  }

  function uploadChunk(file, chunkStart, chunkSize, totalSize, fileName, relativePath, parentId, uploadStatusId, totalFiles, callback) {
    const chunk = file.slice(chunkStart, chunkStart + chunkSize);
    let user = localStorage.getItem('user');
    user = JSON.parse(user);

    const formData = new FormData();
    formData.append('chunk', chunk, fileName);
    formData.append('relativePath', relativePath);
    formData.append('chunkStart', chunkStart);
    formData.append('totalSize', totalSize);
    formData.append('is_drag_folder', true);
    formData.append('parent_id', parentId);
    formData.append('user_id', user.id);
    formData.append('upload_status_id', uploadStatusId);
    formData.append('total_files', totalFiles);
    let retries = 0;

    $.ajax({
      url: `/company_documents/${user.company_id}/upload`,
      type: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      dataType: 'json',
      success: function (data, status) {
        callback();
        if (status == "success") {
          $('#upload_docs').modal('hide');
          $('.success_message').text('Folder was uploaded successfully').show();

          if (typeof onUploadSuccess === 'function') {
            // console.log('Upload chunk successfully.')
            // onUploadSuccess();
          }
        } else {
          alert("Something went wrong");
        }
      },
      error: function(xhr, status, error) {
        if (retries >= 3) {
          updateUploadStatus({
            upload_status_id: uploadStatusId,
            status: 'failed'
          })
          toast.warning('Recent chunk upload failed!')
        } else {
          retries += 1;
          uploadChunk(file, chunkStart, chunkSize, totalSize, fileName, relativePath, parentId, uploadStatusId, totalFiles, callback)
        }
      }
    })
  }

  function updateUploadStatus(uploadStatusId, status = 'failed') {
    $.ajax({
      url: `/upload_statuses/${uploadStatusId}`,
      method: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      dataType: 'json',
      success: function (data, status) {
        toast.warning('Recent upload failed...');
        $('.recent-upload-container').fadeOut();
      },
    })
  }

  function traverseFileTreePromise(item, path = '', folder, metadata = {}) {
    return new Promise(resolve => {
      if (item.isFile) {
        item.file(file => {
          console.log(`Traverse - ${metadata.count} - ${file.name}`)
          let reader = new FileReader();
          reader.onloadend = () => {
            if (file.name.startsWith('.')) {
              resolve();
            } else {
              folder.push({ file_buffer: reader.result, file_name: file.name, relative_path: path + file.name });
              metadata.count += 1;
              resolve();
            }
          }
          reader.onerror = () => {
            console.error(`Failed to read file: ${file.name}`)
            metadata.count -= 1;
            resolve();
          }
          reader.readAsArrayBuffer(file)
        });
      } else if (item.isDirectory) {
        let dirReader = item.createReader();
        let entriesPromises = [];
        let sub_folder = [];
        folder.push({ folder_name: item.name, relative_path: path + item.name + '/', sub_folder: sub_folder });

        function readAllEntries() {
          dirReader.readEntries(entries => {
            if (entries.length > 0) {
              for (const entry of entries) {
                entriesPromises.push(traverseFileTreePromise(entry, path + item.name + '/', sub_folder, metadata));
              }
              readAllEntries();
            } else {
              Promise.all(entriesPromises).then(() => {
                resolve()
              });
            }
          })
        }

        readAllEntries();
      }
    })
  }
