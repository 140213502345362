 import { Paper, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import ViewPageHeader, {HeaderDetails, ImageContiner, QRCodeViewer} from '../../../shared/components/viewPageHeader';
import PlainTabs from '../../../shared/components/plainTabs';
import ViewListWithFilter from '../../../shared/components/viewDataListWithFilter';
import { Box } from '@mui/material';
import useFetch from '../../../shared/components/userFetch';
import SharerdFormList from './SharedFormList';
import { withRouter } from "react-router-dom";
import CompanyDocs from './CompanyDocs';

export const ViewPageWrapper = styled('div')`
    background: #FFF;
    width: 100%;
    height: calc(100vh - 70px);
    overflow-y: auto;
    .header {
        background: #F6F6F6;
    }
    .v-container {
        width: 90%;
        margin: 0 auto;
        padding: 24px 0px;
    }
    .tab-container {
        width: 90%;
        margin: 0 auto;
    }
`;

const GlobalProjectViewTabs = [
    {
        label: 'Company Information',
        value: 'ci'
    },
    {
        label: 'Shared Forms',
        value: 'sf'
    }
];


export const NoData = () => {
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '400px'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <svg width="52" height="71" viewBox="0 0 52 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9101_39195)">
                <path d="M0.212835 68.2301V31.671L4.02095 20.2379C4.31344 19.3598 5.1348 18.7676 6.05976 18.7676H17.4045V25.2191C17.4045 26.4069 18.3665 27.3695 19.5534 27.3695H32.4473C33.6342 27.3695 34.5962 26.4069 34.5962 25.2191V18.7676H45.9409C46.8659 18.7676 47.6872 19.3598 47.9797 20.2379L51.7879 31.671V46.7252V46.7515V42.4241V68.2303C51.7879 69.418 50.8259 70.3807 49.639 70.3807H2.36077C1.17389 70.3807 0.211914 69.418 0.211914 68.2303L0.212835 68.2301ZM2.47792 31.671H4.51092V25.5683L2.47792 31.671ZM6.65978 31.671H15.2556V40.2729C15.2556 41.4606 16.2179 42.4237 17.4048 42.4237H34.5961C35.7829 42.4237 36.7453 41.4606 36.7453 40.2729V31.671H45.3411V20.9181H36.7453V25.2192C36.7453 27.5946 34.8209 29.5204 32.4472 29.5204H19.5533C17.1796 29.5204 15.2552 27.5946 15.2552 25.2192V20.9181H6.65941L6.65978 31.671ZM47.4901 31.671H49.5231L47.4901 25.5683V31.671ZM13.1067 33.8214H2.36151V68.2302H49.6397V33.8214H38.8945V40.2729C38.8945 42.6483 36.9701 44.5741 34.5964 44.5741H17.4052C15.0315 44.5741 13.1071 42.6483 13.1071 40.2729L13.1067 33.8214ZM22.7772 61.7787H31.373C31.9666 61.7787 32.4476 62.26 32.4476 62.8537C32.4476 63.4477 31.9666 63.9291 31.373 63.9291H22.7772C22.1839 63.9291 21.7025 63.4477 21.7025 62.8537C21.7025 62.26 22.1839 61.7787 22.7772 61.7787ZM22.7772 57.4775H35.671C36.2643 57.4775 36.7457 57.9589 36.7457 58.5529C36.7457 59.1466 36.2643 59.6279 35.671 59.6279H22.7772C22.1839 59.6279 21.7025 59.1466 21.7025 58.5529C21.7025 57.9589 22.1839 57.4775 22.7772 57.4775ZM7.73458 57.4775H18.4789C19.0725 57.4775 19.5535 57.9589 19.5535 58.5529V62.8537C19.5535 63.4477 19.0725 63.9291 18.4789 63.9291H7.73458C7.14097 63.9291 6.65997 63.4477 6.65997 62.8537V58.5529C6.65997 57.9589 7.14097 57.4775 7.73458 57.4775ZM8.80883 59.6279V61.7787H17.405V59.6279H8.80883Z" fill="#909090"/>
                <path d="M23.9537 1.42913C23.9537 0.849938 23.4916 0.380615 22.9213 0.380615C22.3514 0.380615 21.8893 0.849938 21.8893 1.42913V14.171C21.8893 14.7499 22.3514 15.2192 22.9213 15.2192C23.4916 15.2192 23.9537 14.7499 23.9537 14.171V1.42913Z" fill="#909090"/>
                <path d="M17.7602 3.52579C17.7602 2.94695 17.2981 2.47728 16.7278 2.47728C16.1579 2.47728 15.6958 2.94695 15.6958 3.52579V14.171C15.6958 14.7499 16.1579 15.2192 16.7278 15.2192C17.2981 15.2192 17.7602 14.7499 17.7602 14.171V3.52579Z" fill="#909090"/>
                <path d="M30.1472 1.42913C30.1472 0.849938 29.6851 0.380615 29.1149 0.380615C28.5449 0.380615 28.0828 0.849938 28.0828 1.42913V14.171C28.0828 14.7499 28.5449 15.2192 29.1149 15.2192C29.6851 15.2192 30.1472 14.7499 30.1472 14.171V1.42913Z" fill="#909090"/>
                <path d="M36.3408 3.52579C36.3408 2.94695 35.8787 2.47728 35.3084 2.47728C34.7385 2.47728 34.2764 2.94695 34.2764 3.52579V14.171C34.2764 14.7499 34.7385 15.2192 35.3084 15.2192C35.8787 15.2192 36.3408 14.7499 36.3408 14.171V3.52579Z" fill="#909090"/>
                </g>
                <defs>
                <clipPath id="clip0_9101_39195">
                <rect width="51.5759" height="70" fill="white" transform="translate(0.211914 0.380615)"/>
                </clipPath>
                </defs>
                </svg>
                <p style={{color: '#909090', fontSize: '16px'}}>No Data</p>
            </div>
        </div>
    )
}


const SharedForms = ({group}) => {
    return (
        <>
           <SharerdFormList group={group} />
        </>
    )
}

const CompanyDocsList = ({group}) => {
    return (
        <>
           <CompanyDocs group={group} />
        </>
    )
}

const CompanyInformation = ({managers, users, companies, qr_code_details={}}) => {
    return (
        <Box style={{display: 'flex', gap: '24px', flexWrap: 'wrap'}}>
            <ViewListWithFilter
                items={managers}
                title="Managers"
                width="initial"
                style={{flex: '1 1 calc(25% - 24px)', minWidth: '200px'}}
            />
            <ViewListWithFilter
                items={users}
                title="Users"
                width="initial"
                style={{flex: '1 1 calc(25% - 24px)', minWidth: '200px'}}
            />
            <ViewListWithFilter
                items={companies}
                title="Global Project- Companies"
                width="initial"
                style={{flex: '1 1 calc(25% - 24px)', minWidth: '200px'}}
            />
            <QRCodeViewer data={qr_code_details} />
        </Box>
    )
}

const GlobalProjectView = ({match}) => {
    const { id } = match.params;
    const userGroup = match.url.split('/')[1] || '';
    const {data: responseData, isLoading} = useFetch(`/api/v2/entity_management/${userGroup === 'company_groups' ? 'groups' : 'global_projects'}/${id}?view_type=group_info`);
    const {data} = responseData || {};
    const tabsContent = userGroup === 'company_groups' ? [...GlobalProjectViewTabs, {
        label: 'Company Docs',
        value: 'cd'
    }] : GlobalProjectViewTabs ;
    const {
        name,
        description,
        parent_name,
        admin_name,
        job_id,
        time_zone,
        address,
        city,
        postal_code,
        manager_users=[],
        member_users = [],
        group_companies = [],
        qr_code_details={},
        logo_details = {}
    } = data || {};
    return (
        <ViewPageWrapper>
            <div className="header">
                <div className="v-container">
                   <ViewPageHeader
                        title={name}
                        items={[
                            {name: userGroup === 'company_groups' ? 'Company Group' : 'Assigned Global Project List', url: `/user_group?tab=${userGroup === 'company_groups' ? 'cg' : 'agp'}`},
                            {name: userGroup === 'company_groups' ? 'View Company Group' : 'View Assigned Global Project'}
                        ]}
                        backUrl={`/user_group?tab=${userGroup === 'company_groups' ? 'cg' : 'agp' }`}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <HeaderDetails
                            width={'100%'}
                            items={[
                                {
                                    label: 'Description',
                                    value: description,
                                    width: 'w50'
                                },
                                {
                                    label: 'Parent Group',
                                    value: parent_name,
                                    width: 'w16'
                                },
                                {
                                    label: 'Admin',
                                    value: admin_name,
                                    width: 'w16'
                                },
                                {
                                    label: 'Job ID',
                                    value: job_id,
                                    width: 'w16',
                                    noBr: true
                                },
                                {
                                    isDivider: true
                                },
                                {
                                    label: 'Time Zone',
                                    value: time_zone,
                                    width: 'w25'
                                },
                                {
                                    label: 'Address',
                                    value: address,
                                    width: 'w25'
                                },
                                {
                                    label: 'City',
                                    value: city,
                                    width: 'w25'
                                },
                                {
                                    label: 'Postal Code',
                                    value: postal_code,
                                    width: 'w25',
                                    noBr: true
                                }
                            ]}
                        />
                        <ImageContiner text={name} image={logo_details.download_url || null }/>
                   </div>
                </div>
            </div>
            <div className="tab-wrap">
                <PlainTabs isViewPageTab tabList={tabsContent}>
                    {(active) => (
                        <>
                            {active === 'ci' && <div className='v-container'><CompanyInformation qr_code_details={qr_code_details} managers={manager_users} users={member_users} companies={group_companies} /></div>}
                            {active === 'sf' && <div className='v-container'><SharedForms group={userGroup === 'company_groups' } /></div>}
                            {active === 'cd' && userGroup === 'company_groups' && <div className='v-container'><CompanyDocsList group={true} /></div>}
                        </>
                    )}
                </PlainTabs>
            </div>
        </ViewPageWrapper>
    )
}

export default withRouter(GlobalProjectView);
