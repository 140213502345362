import React, { useEffect, useMemo, useState } from "react";
import ActiveFilters from "../../../shared/components/activeFilters";
import CustomTable, {
  HeaderItem,
  ActionMenu,
  TagRender,
  CustomPagination,
} from "../../../shared/components/table";
import {
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import useFetch from "../../../shared/components/userFetch";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import useDelete from "../../../shared/components/useDelete";
import { UserGroupIcons } from "../../../shared/components/userGroupIcons";
import { setPageCount } from "../../../../reducers/common";
import { connect } from "react-redux";

const GlobalProjectList = ({
  handleOnFilterChange,
  filterState,
  filterData,
  setFilterState,
  handleSort,
  setEdit,
  pageCount
}) => {
  const {
    data: responseData,
    loading,
    error,
    refetch,
  } = useFetch("/api/v2/entity_management/global_projects", {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });
  const { data: baseData, loading: baseDataLoading } = useFetch(
    "/api/v2/entity_management/global_projects/base_data"
  );
  const { mutate, isLoading: isDeleting } = useDelete(
    "/api/v2/entity_management/global_projects"
  );
  const { data = [], metadata = {} } = responseData || {};
  const {
    companies = [],
    groups = [],
    managers = [],
    permissions: {
      allow_view = true,
      allow_edit = true
    } = {}
  } = baseData || {};

  useEffect(() => {
    metadata.count && setPageCount({agp: {
      assigned_global_project: metadata.count || pageCount.agp.assigned_global_project,
      invite_pending_accept: pageCount.agp.invite_pending_accept,
      invite_request: pageCount.agp.invite_request
    }})
  }, [metadata.count]);

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!loading) {
      refetch({
        filters: filterData,
        pagination: { page: filterState.page, limit: filterState.limit },
        sort: filterState.sort,
      });
    }
  }, [filterState]);

  const groupsFilterData = [
    { label: "All", value: "All" },
    ...groups.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];

  const companiesFilterData = [
    { label: "All", value: "All" },
    ...companies.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];

  const handleEdit = (row, setClose) => {
    setEdit(row.id);
    if (setClose) {
      setClose();
    }
  };

  const headers = [
    <TableCell className="left">
      <HeaderItem
        title="Assigned Global Projects Name"
        isSort
        handleSort={() => handleSort("name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || "All", "id_eq")}
            value={
              groupsFilterData.find((e) => e.value === filterData["id_eq"]) ||
              "All"
            }
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Description"
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Parent Company"
        isSort
        handleSort={() => handleSort("company_id")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "company_id_eq")
            }
            value={
              companiesFilterData.find(
                (e) => e.value === filterData["company_id_eq"]
              ) || "All"
            }
            options={companiesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Date Joined"
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          name,
          description,
          parent_company,
          date_joined,
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell className="left">
              <div className="flex">
                <div className="flex" style={{ alignItems: "center" }}>
                <Link to={`/global_projects/view/${ele.id}`}>
                  <div style={{ color: '#080808', textDecoration: 'underline', fontWeight: '700'}}>{name}</div>
                 </Link>
                </div>
              </div>
            </TableCell>
            <TableCell>
              {description}
            </TableCell>
            <TableCell>
              {parent_company}
            </TableCell>
            <TableCell>
              {date_joined}
            </TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
              <ActionMenu>
                {(setClose) => {
                  const menuItems = [];
                  if (allow_view) {
                    menuItems.push(<MenuItem onClick={() => console.log(ele, setClose)}>
                      <Link to={`/global_projects/view/${ele.id}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {UserGroupIcons.view}{" "}
                          <span style={{ paddingLeft: 5, color: "#080808" }}>
                            View
                          </span>
                        </Box>
                      </Link>
                    </MenuItem>)
                  }
                  if (allow_edit) {
                    menuItems.push(<MenuItem onClick={() => handleEdit(ele, setClose)}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {UserGroupIcons.edit}{" "}
                        <span style={{ paddingLeft: 5, color: "#080808" }}>
                          Edit Details
                        </span>
                      </Box>
                    </MenuItem>)
                  }
                  return menuItems;
                }}
              </ActionMenu>
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <ActiveFilters filterData={filterState} update={setFilterState} keyName='agp' />
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalProjectList);