import React, { useEffect, useRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import DualListbox from "../../../shared/components/dualListBox";
import SearchIcon from "../../../../assets/fonts/Search.svg";

export const NewInviteForm = ({
  register,
  errors,
  setValue,
  control,
  baseData = []
}) => {
  const elementRef = useRef(null);
  console.log(baseData, "companies");
  
  useEffect(() => {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView();
    }
  }, []);

  const companiesOptionsData = baseData.map((v) => {
    return {...v, label: v.key, value: v.value}
  })
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="group_managers"
            control={control}
            render={({ field }) => (
                <Autocomplete
                  // multiple
                  size="small"
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  onChange={(_, data) => field.onChange(data)} // update field value
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={companiesOptionsData}
                  popupIcon={<img src={SearchIcon} />}
                  sx={{ width: "100%", background: "#fff", border: "none" }}
                  renderInput={(params) => <TextField {...params} label="Search" />}
              />
            )}
          />         
        </Grid>
      </Grid>
    </>
  );
};
