import { styled } from "@mui/material";

const FormWrapper = styled('div')`
    input, textarea {
        font-size: 14px;
        &::placeholder {
            font-style: italic;
        }
    }
    .f-label {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #5C5C5C;
        &.d-label {
            color: #909090;
        }
    }
    .grey-box {
        background-color: #F6F6F6;
        display: flex;
        padding: 10px;
        flex-direction: column;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 4px;
    }
    .f-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 80%;
        background-color: #fff;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        padding: 4px;
    }
    .MuiSwitch-thumb {
        color: #407797
    }
    .MuiStepLabel-alternativeLabel {
        cursor: pointer
    }
`;

export default FormWrapper;
